import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Radio, Select, Modal, Tooltip, Carousel, Image, Input, Button, Form, DatePicker, Checkbox } from 'antd';
import moment from 'moment';
import Axios from 'axios';
import LS_SERVICE from '../../../utils/localStorage';
import { 
  RISK_FACTORS_LIST,
  OBJECTIVE_OBSGYNAE_STORE_SS,
  RELATIONSHIP_TO_PP_BRIM,
  PRESENTATION,
  FETAL_MOVEMENT,
  PREVIOUS_OBSGYNAE_COPY_STATUS,
  OBSTETRIC_HISTORY_LIST,
  CONCEPTION_OPTIONS,
  PREGNANCY_OPTIONS,
  DELIVERY_OPTIONS,
  OUTCOME_OPTIONS,
  PLACE_OF_DELIVERY_OPTIONS
} from '../../../utils/constant';
import { speechService, speechTextService } from '../../../utils/rxjs-sharing';

const { Option } = Select;
const { TextArea } = Input;

export default class GynaeSingleScreen extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        this.state = {
            menarch_age: null,
            regularity: null,
            menstrual_remarks: null,
            duration_of_period: null,
            sexually_active:"Yes",
            since:'',
            since_by:1,
            details:'',
            contraceptive_detail:'' ,
            contraceptives:[],
            cycle_length: null,
            lmp: null,
            edd_by_lmp: null,
            corrected_edd: null,
            pregnant: "0",
            is_checked_aph: null,
            is_confidential: false,
            is_sexual_hx_confidential: '0',
            is_sexual_history_data_confidential: '0',
            mother_fetal_printwithprescription: false,
            obsHistoryListPopupVisible: false,
            obsHistoryList: [],
            test_done: [],
            conception_options: CONCEPTION_OPTIONS,
            pregnancy_options: PREGNANCY_OPTIONS,
            delivery_options: DELIVERY_OPTIONS,
            outcome_options: OUTCOME_OPTIONS,
            place_of_delivery_options: PLACE_OF_DELIVERY_OPTIONS,
            any_previous_complication: [],
            lactating: false,
            risk_factors: [],
            current_pregnancy_remarks: "",
            mother_remarks: "",
            sexual_remarks: "",
            gravidity: null,
            parity: null,
            abortion: null,
            live_birth: null,
            ectopic: null,
            lscs: null,
            miscarriage: null,
            mtp: null,
            preterm: null,
            term: null,
            still_birth: null,
            gestational_age_lmp_weeks: null,
            gestational_age_lmp_days: null,
            gestational_age_usg_weeks: null,
            gestational_age_usg_days: null,
            remarks: "",
            physicalExam: this.props.physicalExamData,
            physicalExamChecked: false,
              sinceByList: [
                {id: 1, desc: 'Days'},
                {id: 2, desc: 'Weeks'},
                {id: 3, desc: 'Months'},
                {id: 4, desc: 'Years'}
              ],
            obsGyaneData: this.props.obsGyaneData,
            fetalParamsPrevData: this.props.fetalParamsPrevData,
            prevPregnencyCopied: this.props.prevPregnencyCopied,
            obsGynaePrevData: this.props.obsGynaePrevData,
            prevRecordedObsGynaePopupVisible: false,
            physicalExamPrevData: this.props.physicalExamPrevData,
            no_of_fetus: this.props.fetalParamsData?.length || null,
            multipleFetalParams: this.props.fetalParamsData || [],
            initialData:
                typeof this.props.obsGyaneData != "undefined" && this.props.obsGyaneData != null && this.props.obsGyaneData.length > 0 ?
                    { ...this.props.obsGyaneData[0] } :
                    {
                        menarch_age: null,
                        regularity: null,
                        menstrual_remarks: null,
                        duration_of_period: null,
                        sexually_active:"Yes",
                        since:'',
                        since_by:1,
                        details:'',
                        cycle_length: null,
                        contraceptive_detail:'' ,
                        contraceptives: [],
                        lmp: null,
                        edd_by_lmp: null,
                        corrected_edd: null,
                        pregnant: "0",
                        is_checked_aph: null,
                        test_done: [],
                        any_previous_complication: [],
                        lactating: false,
                        is_confidential: false,
                        is_sexual_hx_confidential: '0',
                        is_sexual_history_data_confidential: '0',
                        mother_fetal_printwithprescription: false,
                        risk_factors: [],
                        current_pregnancy_remarks: "",
                        mother_remarks: "",
                        sexual_remarks: "",
                        gravidity: null,
                        parity: null,
                        abortion: null,
                        live_birth: null,
                        ectopic: null,
                        lscs: null,
                        miscarriage: null,
                        mtp: null,
                        preterm: null,
                        term: null,
                        still_birth: null,
                        gestational_age_lmp_weeks: null,
                        gestational_age_lmp_days: null,
                        gestational_age_usg_weeks: null,
                        gestational_age_usg_days: null,
                        remarks: "",
                    },
            speech: {
                identifier: null,
                state: false
            },

            vitalsData: this.props.vitalsData

        }

    }


    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.vitalsData !== prevState.vitalsData) {
            return { vitalsData: nextProps.vitalsData }
        }
        return null;
    }

    async componentDidMount() {
        await this.optionRiskFactorsList();
        const ss_configuration = LS_SERVICE.get('ss_configuration');
        const patient_full_data = LS_SERVICE.get('call_patient_detail');
        this.setState({
            ss_configuration: ss_configuration,
            consult_type: patient_full_data?.consult_type == 1 ? 'vc' : 'opd'
        })
        this.subscription = speechTextService.status().subscribe(data => {
            if (this.state.speech.state) {

                if (this.state.speech.identifier === 'remarks') {
                    this.setState({ remarks: data.text });

                }
                if (this.state.speech.identifier === 'current_pregnancy_remarks') {
                    this.setState({ current_pregnancy_remarks: data.text });
                }
                if (this.state.speech.identifier === 'mother_remarks') {
                  this.setState({ mother_remarks: data.text });
                }
                if (this.state.speech.identifier === 'sexual_remarks') {
                  this.setState({ sexual_remarks: data.text });
                }
            }
        });
        this.updateVitals();
        this.obstetricList();
        await this.prevRecordedObsGynae()
    }

    async componentWillUnmount() {
        await this.saveData();

    }

    autoHeight = (e) => {
        if (e)
            e.target.style.height = e.target.scrollHeight + "px";
    }

    prevRecordedObsGynae = async (e, type) => {
        if(LS_SERVICE.get('call_patient_detail')?.gender == 'Female' && this.state.obsGynaePrevData?.pregnant == "1" && this.state.prevPregnencyCopied == null){
            this.setState({
                prevRecordedObsGynaePopupVisible: true
            })
        }
    }

    closePopup = async (e, type) => {
        var closePopup = {};
        if(type.includes('prevRecordedObsGynae')){
          closePopup.prevRecordedObsGynaePopupVisible = false
        }
        if(type.includes('obsHistoryList')){
          closePopup.obsHistoryListPopupVisible = false
        }
        this.setState(closePopup)
    }

    copyPrevRecordedObsGynae = async (e, value) => {
        var { initialData } = this.state;
        var obsGynaePrevData = this.state.obsGynaePrevData;
        if(value == 'Y'){
            const currentDate = moment();
            const lmpDate = moment(obsGynaePrevData.lmp, "YYYY-MM-DD");
            const duration = moment.duration(currentDate.diff(lmpDate));
            var gestational_age_lmp_weeks = Math.floor(duration.asDays() / 7);
            var gestational_age_lmp_days = Math.round(duration.asDays() % 7);
            if(gestational_age_lmp_days == 7){
                gestational_age_lmp_weeks = gestational_age_lmp_weeks+1;
                gestational_age_lmp_days = 0;
            }
            if(gestational_age_lmp_weeks < 0 ){
                gestational_age_lmp_weeks = 0
            }
            if(gestational_age_lmp_days < 0 ){
                gestational_age_lmp_days = 0
            }
            var risk_factor_array = obsGynaePrevData?.risk_factors?.split(',');
            if(this.state.fetalParamsPrevData?.noOfFetus && this.state.fetalParamsPrevData?.noOfFetus>0){
              this.handleFetusChange(this.state.fetalParamsPrevData?.noOfFetus)
            }

            initialData.pregnant = "1";
            initialData.cycle_length = obsGynaePrevData.cycle_length || null;
            initialData.menarch_age = obsGynaePrevData.menarch_age || null;
            initialData.duration_of_period = obsGynaePrevData.duration_of_period || null;
            initialData.regularity = obsGynaePrevData.regularity || null;
            initialData.menstrual_remarks = obsGynaePrevData.menstrual_remarks || null;
            initialData.lmp = obsGynaePrevData.lmp || null;
            initialData.edd_by_lmp = obsGynaePrevData.edd_by_lmp || null;
            initialData.corrected_edd = obsGynaePrevData.corrected_edd || null;
            initialData.gravidity = obsGynaePrevData.gravidity || null;
            initialData.parity = obsGynaePrevData.parity || null;
            initialData.abortion = obsGynaePrevData.abortion || null;
            initialData.live_birth = obsGynaePrevData.live_birth || null;
            initialData.ectopic = obsGynaePrevData.ectopic || null;
            initialData.lscs = obsGynaePrevData.lscs || null;
            initialData.miscarriage = obsGynaePrevData.miscarriage || null;
            initialData.mtp = obsGynaePrevData.mtp || null;
            initialData.preterm = obsGynaePrevData.preterm || null;
            initialData.term = obsGynaePrevData.term || null;
            initialData.still_birth = obsGynaePrevData.still_birth || null;
            initialData.gestational_age_lmp_weeks = gestational_age_lmp_weeks || null;
            initialData.gestational_age_lmp_days = gestational_age_lmp_days || null;
            initialData.risk_factors = risk_factor_array || [];
            initialData.current_pregnancy_remarks = obsGynaePrevData.current_pregnancy_remarks || "";
            initialData.no_of_fetus = this.state.fetalParamsPrevData?.noOfFetus || null;
            initialData.any_previous_complication = obsGynaePrevData.any_previous_complication || [];
            initialData.is_checked_aph = obsGynaePrevData.is_checked_aph || null;
            initialData.test_done = obsGynaePrevData.test_done || [];
            initialData.remarks = obsGynaePrevData.remarks || "";
            const { physicalExam } = this.state;
            const obj = {
              pog_weeks: gestational_age_lmp_weeks,
            };
            await this.setState({ physicalExam: { ...physicalExam, ...obj } });
            this.props.setPhysicalExamData(this.state.physicalExam);
            this.setState(
                {
                    initialData: initialData,
                    obsGyaneData: [this.state.obsGynaePrevData],
                    pregnant: "1",
                    cycle_length: obsGynaePrevData.cycle_length || null,
                    menarch_age: obsGynaePrevData.menarch_age || null,
                    regularity: obsGynaePrevData.regularity || null,
                    duration_of_period: obsGynaePrevData.duration_of_period || null,
                    menstrual_remarks: obsGynaePrevData.menstrual_remarks || null,
                    lmp: obsGynaePrevData.lmp || null,
                    edd_by_lmp: obsGynaePrevData.edd_by_lmp || null,
                    corrected_edd: obsGynaePrevData.corrected_edd || null,
                    gravidity: obsGynaePrevData.gravidity || null,
                    parity: obsGynaePrevData.parity || null,
                    abortion: obsGynaePrevData.abortion || null,
                    live_birth: obsGynaePrevData.live_birth || null,
                    ectopic: obsGynaePrevData.ectopic || null,
                    lscs: obsGynaePrevData.lscs || null,
                    miscarriage: obsGynaePrevData.miscarriage || null,
                    mtp: obsGynaePrevData.mtp || null,
                    preterm: obsGynaePrevData.preterm || null,
                    term: obsGynaePrevData.term || null,
                    still_birth: obsGynaePrevData.still_birth || null,
                    gestational_age_lmp_weeks: gestational_age_lmp_weeks || null,
                    gestational_age_lmp_days: gestational_age_lmp_days || null,
                    pog_weeks: gestational_age_lmp_weeks || null,
                    risk_factors: risk_factor_array,
                    current_pregnancy_remarks: obsGynaePrevData.current_pregnancy_remarks || "",
                    no_of_fetus: this.state.fetalParamsPrevData?.noOfFetus || null,
                    any_previous_complication: obsGynaePrevData.any_previous_complication || [],
                    is_checked_aph: obsGynaePrevData.is_checked_aph || null,
                    test_done: obsGynaePrevData.test_done || [],
                    remarks: obsGynaePrevData.remarks || ""
                },
                () => {
                    this.formRef.current.setFieldsValue({
                        pregnant: "1",
                        cycle_length: obsGynaePrevData.cycle_length || null,
                        menarch_age: obsGynaePrevData.menarch_age || null,
                        regularity: obsGynaePrevData.regularity || null,
                        duration_of_period: obsGynaePrevData.duration_of_period || null,
                        menstrual_remarks: obsGynaePrevData.menstrual_remarks || null,
                        lmp: obsGynaePrevData.lmp ? moment(obsGynaePrevData.lmp) : null,
                        edd_by_lmp: obsGynaePrevData.edd_by_lmp ? moment(obsGynaePrevData.edd_by_lmp) : null,
                        corrected_edd: obsGynaePrevData.corrected_edd ? moment(obsGynaePrevData.corrected_edd) : null,
                        gravidity: obsGynaePrevData.gravidity || null,
                        parity: obsGynaePrevData.parity || null,
                        abortion: obsGynaePrevData.abortion || null,
                        live_birth: obsGynaePrevData.live_birth || null,
                        ectopic: obsGynaePrevData.ectopic || null,
                        lscs: obsGynaePrevData.lscs || null,
                        miscarriage: obsGynaePrevData.miscarriage || null,
                        mtp: obsGynaePrevData.mtp || null,
                        preterm: obsGynaePrevData.preterm || null,
                        term: obsGynaePrevData.term || null,
                        still_birth: obsGynaePrevData.still_birth || null,
                        gestational_age_lmp_weeks: gestational_age_lmp_weeks || null,
                        gestational_age_lmp_days: gestational_age_lmp_days || null,
                        pog_weeks: gestational_age_lmp_weeks || null,
                        risk_factors: obsGynaePrevData.risk_factors != null && obsGynaePrevData.risk_factors.length > 0 ? obsGynaePrevData.risk_factors.split(',').map(item => { return parseInt(item) }) : [],
                        current_pregnancy_remarks: obsGynaePrevData.current_pregnancy_remarks || "",
                        no_of_fetus: this.state.fetalParamsPrevData?.noOfFetus || null,
                        any_previous_complication: obsGynaePrevData.any_previous_complication || [],
                        is_checked_aph: obsGynaePrevData.is_checked_aph || null,
                        test_done: obsGynaePrevData.test_done || [],
                        remarks: obsGynaePrevData.remarks || ""
                    });
                    this.saveData();
                }
            );
        }
        var params = {
            reference_id: LS_SERVICE.get('call_patient_qms_token_id'),
            reference_type: LS_SERVICE.get('reference_type'),
            status: value
        }
        await Axios.post(PREVIOUS_OBSGYNAE_COPY_STATUS, params);
        this.closePopup(e, ['prevRecordedObsGynae'])
    }

    handleCycleLengthChange = async (e, type) => {
        const { initialData } = this.state;
        let foramttedDate1 = null;
        let formattedDate = moment(e, "DD MMM, YYYY").format("YYYY-MM-DD");
        let edd_by_lmp = this.state.initialData.edd_by_lmp || null;
        let gestational_age_lmp_weeks = this.state.initialData.gestational_age_lmp_weeks;
        let gestational_age_lmp_days = this.state.initialData.gestational_age_lmp_days > 6 ? 0 : this.state.initialData.gestational_age_lmp_days;
        if(type === "gestational_age_usg_days" && e.target.value>6){
          await this.formRef.current.setFieldsValue({
            gestational_age_usg_days: null
          });
        }
        if ((type === "lmp" && e) || (type == "pregnant" && e.target.value == "1" && ((this.state.lmp && this.state.lmp!=null) || (this.state.initialData?.lmp && this.state.initialData?.lmp != null)))) {
            // const lmpDate = moment(e, "DD MMM, YYYY");
            // const currentDate = moment();

            var lmpDate;
            if(type === "lmp" && e){
              lmpDate = moment(e, "DD MMM, YYYY");
            }
            else if(type == "pregnant" && e.target.value == "1" && ((this.state.lmp && this.state.lmp!=null) || (this.state.initialData?.lmp && this.state.initialData?.lmp != null))){
              if(this.state.lmp && this.state.lmp!=null){
                lmpDate = moment(this.state.lmp, "DD MMM, YYYY");
              }
              else if(this.state.initialData?.lmp && this.state.initialData?.lmp != null){
                lmpDate = moment(this.state.initialData.lmp, "YYYY-MM-DD");
              }
            }
            const currentDate = moment();

            const duration = moment.duration(currentDate.diff(lmpDate));
            gestational_age_lmp_weeks = Math.floor(duration.asDays() / 7);
            gestational_age_lmp_days = Math.round(duration.asDays() % 7);
            if(gestational_age_lmp_days == 7){
              gestational_age_lmp_weeks = gestational_age_lmp_weeks+1;
              gestational_age_lmp_days = 0;
            }
            if(gestational_age_lmp_weeks < 0 ){
              gestational_age_lmp_weeks = 0
            }
            if(gestational_age_lmp_days < 0 ){
              gestational_age_lmp_days = 0
            }
            const { physicalExam } = this.state;
            if(this.state.initialData.gestational_age_usg_weeks>0){
              const obj = {
                pog_weeks: this.state.initialData.gestational_age_usg_weeks,
              };
              await this.setState({ physicalExam: { ...physicalExam, ...obj } });
              await this.setState({ physicalExamChecked: true });
              this.props.setPhysicalExamData(this.state.physicalExam);
            }
            else{
              const obj = {
                pog_weeks: gestational_age_lmp_weeks,
              };
              await this.setState({ physicalExam: { ...physicalExam, ...obj } });
              await this.setState({ physicalExamChecked: true });
              this.props.setPhysicalExamData(this.state.physicalExam);
            }

            this.formRef.current.setFieldsValue({
                pog_weeks: this.state.physicalExam.pog_weeks,
                gestational_age_lmp_weeks: gestational_age_lmp_weeks,
                gestational_age_lmp_days: gestational_age_lmp_days
            });
            if (lmpDate && lmpDate.isValid()) {
                edd_by_lmp = lmpDate.add(40, 'weeks').format("YYYY-MM-DD");
                this.disabledDate();
                foramttedDate1 = lmpDate.format("YYYY-MM-DD");
            }
        }
        if(type == "gestational_age_usg_weeks"){
          const { physicalExam } = this.state;
          if(e.target.value > 0){
            const obj = {
              pog_weeks: e.target.value,
            };
            await this.setState({ physicalExam: { ...physicalExam, ...obj } });
            await this.setState({ physicalExamChecked: true });
            this.props.setPhysicalExamData(this.state.physicalExam);
            await this.formRef.current.setFieldsValue({
              pog_weeks: e.target.value
            });
          }
          else if(this.state.initialData.gestational_age_lmp_weeks > 0){
            const obj = {
              pog_weeks: this.state.initialData.gestational_age_lmp_weeks,
            };
            await this.setState({ physicalExam: { ...physicalExam, ...obj } });
            await this.setState({ physicalExamChecked: true });
            this.props.setPhysicalExamData(this.state.physicalExam);
            await this.formRef.current.setFieldsValue({
              pog_weeks: this.state.initialData.gestational_age_lmp_weeks
            });
          }
        }
        let data = {
            ...initialData,
            cycle_length: type === "cycle_length" ? e.target.value : initialData.cycle_length,
            lmp: type === "lmp" ? formattedDate : initialData.lmp,
            edd_by_lmp: edd_by_lmp || initialData.edd_by_lmp || null,
            pregnant: type === "pregnant" ? e.target.value : initialData.pregnant,
            risk_factors: type === "risk_factors" ? e.target.value : initialData.risk_factors,
            current_pregnancy_remarks: type === "current_pregnancy_remarks" ? e.target.value : initialData.current_pregnancy_remarks,
            mother_remarks: type === "mother_remarks" ? e.target.value : initialData.mother_remarks,
            sexual_remarks: type === "sexual_remarks" ? e.target.value : initialData.sexual_remarks,
            gestational_age_lmp_weeks,
            gestational_age_lmp_days,
            gestational_age_usg_weeks: type === "gestational_age_usg_weeks" ? e.target.value : initialData.gestational_age_usg_weeks,
            gestational_age_usg_days: type === "gestational_age_usg_days" ? (e.target.value>6 ? 0 : e.target.value ) : initialData.gestational_age_usg_days,
            remarks: type === "remarks" ? e.target.value : initialData.remarks
        };

        if (type === "pregnant" && e.target.value === "0") {
            data = {
                ...data,
                gestational_age_lmp_weeks: null,
                gestational_age_lmp_days: null,
                gestational_age_usg_weeks: null,
                gestational_age_usg_days: null,
                edd_by_lmp: null,
                corrected_edd: null,
                test_done: [],

            };
            this.formRef.current.setFieldsValue({
                gestational_age_lmp_weeks: null,
                gestational_age_lmp_days: null,
                gestational_age_usg_weeks: null,
                gestational_age_usg_days: null,
                edd_by_lmp: null,
                corrected_edd: null,
                test_done: null
            });

        }


        if (edd_by_lmp != undefined && edd_by_lmp != null && edd_by_lmp !='Invalid date') {
            this.formRef.current.setFieldsValue({ edd_by_lmp: moment(edd_by_lmp) });
        }


        this.setState({
            initialData: data,
            remarks: type === "remarks" ? e.target.value : this.state.remarks,
            current_pregnancy_remarks: type === "current_pregnancy_remarks" ? e.target.value : this.state.current_pregnancy_remarks,
            mother_remarks: type === "mother_remarks" ? e.target.value : this.state.mother_remarks,
            sexual_remarks: type === "sexual_remarks" ? e.target.value : this.state.sexual_remarks,
            lmp: type === "lmp" ? e : this.state.lmp,
            edd_by_lmp: edd_by_lmp || null,
            gestational_age_lmp_weeks,
            gestational_age_lmp_days,
        }, () => {
            this.props.setCycleLength(data);
        });
    };

    handleGPALEChange = (e, type) => {
        const { initialData } = this.state;

        let data = {
            ...initialData,
            gravidity: type === "gravidity" ? e.target.value : initialData.gravidity,
            parity: type === "parity" ? e.target.value : initialData.parity,
            abortion: type === "abortion" ? e.target.value : initialData.abortion,
            live_birth: type === "live_birth" ? e.target.value : initialData.live_birth,
            ectopic: type === "ectopic" ? e.target.value : initialData.ectopic,
            lscs: type === "lscs" ? e.target.value : initialData.lscs,
            miscarriage: type === "miscarriage" ? e.target.value : initialData.miscarriage,
            mtp: type === "mtp" ? e.target.value : initialData.mtp,
            preterm: type === "preterm" ? e.target.value : initialData.preterm,
            term: type === "term" ? e.target.value : initialData.term,
            still_birth: type === "still_birth" ? e.target.value : initialData.still_birth,
        };

        // Check conditions and set fields to null if necessary
        if (data.gravidity) {
            if (data.parity && parseInt(data.parity) > parseInt(data.gravidity)) {
                data.parity = null;
            }

            if (data.abortion && parseInt(data.abortion) > parseInt(data.gravidity)) {
                data.abortion = null;
            }

            if (data.live_birth && parseInt(data.live_birth) > 50) {
                data.live_birth = null;
            }

            if (data.ectopic && parseInt(data.ectopic) > parseInt(data.gravidity)) {
                data.ectopic = null;
            }
        }

        this.setState(
            {
                initialData: data,
                gravidity: null,
                parity: null,
                abortion: null,
                live_birth: null,
                ectopic: null,
                lscs: null,
                miscarriage: null,
                mtp: null,
                preterm: null,
                term: null,
                still_birth: null,
            },
            () => {

                // Set values back to the form fields
                this.formRef.current.setFieldsValue({
                    gravidity: data.gravidity,
                    parity: data.parity,
                    abortion: data.abortion,
                    live_birth: data.live_birth,
                    ectopic: data.ectopic,
                    lscs: data.lscs, 
                    miscarriage: data.miscarriage,
                    mtp: data.mtp,
                    preterm: data.preterm,
                    term: data.term,
                    still_birth: data.still_birth,
                });

                // Call the function to save or perform other actions
                this.props.setCycleLength(data);
            }
        );
    };


    handleLmpChange = async (e, type) => {
        let { initialData } = this.state;
        let data = {
            ...initialData,
            gestational_age_lmp_weeks: type === "gestational_age_lmp_weeks" ? e.target.value : initialData.gestational_age_lmp_weeks,
            gestational_age_lmp_days: type === "gestational_age_lmp_days" ? (e.target.value >6 ? 0 : e.target.value) : initialData.gestational_age_lmp_days,
        };
        if(type === "gestational_age_lmp_days" && e.target.value>6){
          await this.formRef.current.setFieldsValue({
            gestational_age_lmp_days: null
          });
        }
        if (type === "pregnant" && e.target.value === "0") {
            data = {
                ...data,
                gestational_age_lmp_weeks: null,
                gestational_age_lmp_days: null,
            };
            this.formRef.current.setFieldsValue({
                gestational_age_lmp_weeks: null,
                gestational_age_lmp_days: null,
            });

        }
        const { physicalExam } = this.state;
        if(type == "gestational_age_lmp_weeks" && (data.gestational_age_usg_weeks<=0 || !data.gestational_age_usg_weeks || data.gestational_age_usg_weeks=='')){
          const obj = {
            pog_weeks: data.gestational_age_lmp_weeks,
          };
          await this.setState({ physicalExam: { ...physicalExam, ...obj } });
          await this.setState({ physicalExamChecked: true });
          this.props.setPhysicalExamData(this.state.physicalExam);
          await this.formRef.current.setFieldsValue({
            pog_weeks: data.gestational_age_lmp_weeks
          });
        }
        this.setState({
            initialData: data,
        }, () => {
            this.props.setCycleLength(data);

        });

    };

    handleEDDChange = (e) => {
        const { initialData } = this.state;
        const eddByLMP = moment(e, "DD MMM, YYYY").format("YYYY-MM-DD");

        const data = {
            ...initialData,
            edd_by_lmp: eddByLMP || null,
        };

        this.setState({
            initialData: data,
            edd_by_lmp: eddByLMP || null,
        })
        this.props.setCycleLength(data);
    };

    handleCorrecteEddChange = (e) => {
        const { initialData } = this.state;
        const correctedEdd = moment(e, "DD MMM, YYYY").format("YYYY-MM-DD");

        const data = {
            ...initialData,
            corrected_edd: correctedEdd,
        };

        this.setState({
            initialData: data,
            corrected_edd: correctedEdd,
        });
        this.props.setCycleLength(data);
    };

    handleRadioChange = (e, type) => {
        const { initialData } = this.state;

        let data = {
            ...initialData,
            is_checked_aph: type === "is_checked_aph" ? e.target.value : initialData.is_checked_aph,
        };

        if (type === "is_checked_aph" && e.target.value === "0") {
            data = {
                ...data,
                any_previous_complication: []
            };
            this.formRef.current.setFieldsValue({
                any_previous_complication: null
            })

        }

        this.setState({
            initialData: data
        })
        this.props.setCycleLength(data);
    }

    handleTestDoneChange = async (e, val) => {
        let updatedTestDone = [...this.state.initialData.test_done];

        if (e.target.checked) {
            updatedTestDone.push(val);
        } else {
            // If checkbox is unchecked, remove the value from the array
            updatedTestDone = updatedTestDone.filter(item => item != val);

        }

        // Update initialData
        const { initialData } = this.state;
        const data = {
            ...initialData,
            test_done: updatedTestDone,
        };

        // Update the state with the new test_done array
        this.setState({ initialData: data, test_done: updatedTestDone })
        this.props.setCycleLength(data);
    }

    handleAnyPreviousChange = async (e, val) => {
        let updatedAnyPreviousComplication = [...this.state.initialData.any_previous_complication];

        if (e.target.checked) {
            updatedAnyPreviousComplication.push(val);
        } else {
            // If checkbox is unchecked, remove the value from the array
            updatedAnyPreviousComplication = updatedAnyPreviousComplication.filter(item => item != val);

        }

        // Update initialData
        const { initialData } = this.state;
        const data = {
            ...initialData,
            any_previous_complication: updatedAnyPreviousComplication,
        };

        // Update the state with the new test_done array
        this.setState({ initialData: data, any_previous_complication: updatedAnyPreviousComplication })
        this.props.setCycleLength(data);
    }

    handleLactatingChange = (e) => {
        const checked = e.target.checked;
        const value = checked ? '1' : '0';

        this.setState((prevState) => ({
            initialData: {
                ...prevState.initialData,
                lactating: value,
            }
        }), () => {
            this.props.setCycleLength(this.state.initialData);
        });
    }

    async saveData(from_page=null) {
        try {
            const params = {
                reference_id: LS_SERVICE.get('call_patient_qms_token_id'),
                reference_type: LS_SERVICE.get('reference_type'),
                patient_id: LS_SERVICE.get('call_patient_id'),
                hospital_id: LS_SERVICE.get('slot_hospital_id'),
                doctor_id: LS_SERVICE.get("staff_id"),
                gender: LS_SERVICE.get('call_patient_detail').gender || null,
                cycle_length: this.state.initialData.cycle_length,
                menarch_age: this.state.initialData.menarch_age,
                regularity: this.state.initialData.regularity,
                duration_of_period: this.state.initialData.duration_of_period,
                menstrual_remarks: this.state.initialData.menstrual_remarks,
                lmp: this.state.initialData.lmp,
                corrected_edd: this.state.initialData.corrected_edd,
                edd_by_lmp: this.state.initialData.edd_by_lmp || null,
                is_confidential: this.state.initialData.is_confidential,
                is_sexual_hx_confidential: this.state.initialData.is_sexual_hx_confidential,
                is_sexual_history_data_confidential: this.state.initialData.is_sexual_history_data_confidential,
                mother_fetal_printwithprescription: this.state.initialData.mother_fetal_printwithprescription,
                current_pregnancy_remarks: this.state.initialData.current_pregnancy_remarks,
                mother_remarks: this.state.initialData.mother_remarks,
                sexual_remarks: this.state.initialData.sexual_remarks,
                sexually_active: this.state.initialData.sexually_active,
                since: this.state.initialData.since,
                since_by: this.state.initialData.since_by,
                details: this.state.initialData.details,
                contraceptive_detail: this.state.initialData.contraceptive_detail,
                gravidity: this.state.initialData.gravidity,
                parity: this.state.initialData.parity,
                abortion: this.state.initialData.abortion,
                ectopic: this.state.initialData.ectopic,
                live_birth: this.state.initialData.live_birth,
                lscs: this.state.initialData.lscs,
                miscarriage: this.state.initialData.miscarriage,
                mtp: this.state.initialData.mtp,
                preterm: this.state.initialData.preterm,
                term: this.state.initialData.term,
                still_birth: this.state.initialData.still_birth,
                gestational_age_lmp_weeks: this.state.initialData.gestational_age_lmp_weeks,
                gestational_age_lmp_days: this.state.initialData.gestational_age_lmp_days > 6 ? "0" : this.state.initialData.gestational_age_lmp_days,
                gestational_age_usg_weeks: this.state.initialData.gestational_age_usg_weeks,
                gestational_age_usg_days: this.state.initialData.gestational_age_usg_days > 6 ? "0" : this.state.initialData.gestational_age_usg_days,
                remarks: this.state.initialData.remarks,
                risk_factors: this.state.initialData.risk_factors,
                pregnant: this.state.initialData.pregnant,
                is_checked_aph: this.state.initialData.is_checked_aph,
                test_done: this.state.initialData.test_done,
                contraceptives: this.state.initialData.contraceptives,
                any_previous_complication: this.state.initialData.any_previous_complication,
                lactating: this.state.initialData.lactating,
                multipleFetalParams: this.state.multipleFetalParams,
                physicalExamData: this.state.physicalExam,
            };

            if(from_page == "from_ssp"){
                const falsyExemptKeys = ['reference_id', 'reference_type', 'patient_id', 'hospital_id', 'doctor_id'];
                let saveGynaeData = false;
                for (const key in params) {
                    if (!falsyExemptKeys.includes(key) && params[key]) {
                        saveGynaeData = true
                    } else if (!falsyExemptKeys.includes(key) && params[key] == 0) {
                        saveGynaeData = true;
                    } else{
                        saveGynaeData = false;
                    }
                }

                if(saveGynaeData){
                    const response = await Axios.post(OBJECTIVE_OBSGYNAE_STORE_SS, params);
                    if (response.data.status === true) {
                        console.log("Data saved Successfully");
                    } else {
                        console.error('Error while saving data:', response.data.message);
                    }
                }
            }else{
                const response = await Axios.post(OBJECTIVE_OBSGYNAE_STORE_SS, params);
                if (response.data.status === true) {
                    console.log("Data saved Successfully");
                } else {
                    console.error('Error while saving data:', response.data.message);
                }
            }   

        } catch (error) {
            console.error('Error while making the API call:', error);
        }
    }

    handleConfidentialChange = (e) => {
        const checked = e.target.checked;
        const value = checked ? '1' : '0';

        this.setState((prevState) => ({
            initialData: {
                ...prevState.initialData,
                is_confidential: value,
            }
        }), () => {
            this.props.setCycleLength(this.state.initialData);
        });
    }

    handleSexualConfidentialChange = (e) => {
      const checked = e.target.checked;
      const value = checked ? '1' : '0';

      this.setState((prevState) => ({
          initialData: {
              ...prevState.initialData,
              is_sexual_hx_confidential: value,
          }
      }), () => {
          this.props.setCycleLength(this.state.initialData);
      });
    }

    handleSexualDataConfidentialChange = (e) => {
        const checked = e.target.checked;
        const value = checked ? '1' : '0';

        this.setState((prevState) => ({
            initialData: {
                ...prevState.initialData,
                is_sexual_history_data_confidential: value,
            }
        }), () => {
            this.props.setCycleLength(this.state.initialData);
        });
    }

    handleMotherFetalPrintOnPrescriptionConfidentialChange = (e) => {
      const checked = e.target.checked;
      const value = checked ? '1' : '0';

      this.setState((prevState) => ({
          initialData: {
              ...prevState.initialData,
              mother_fetal_printwithprescription: value,
          }
      }), () => {
          this.props.setCycleLength(this.state.initialData);
      });
    }

    optionRiskFactorsList() {
        Axios.get(RISK_FACTORS_LIST).then(async success => {
            if (success?.data?.status == true) {
                this.setState({ risk_factors_list: success.data.data }, () => {
                });
            }
        }).catch(err => console.log(err));


    }

    optionRiskFactorsLoad() {
        if (!this.state.risk_factors_list || this.state.risk_factors_list.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }
        return this.state.risk_factors_list.map((obj, i) => {
            return (<Option key={i} value={parseInt(obj.id)} title={obj.description}>{obj.description}</Option>)
        }, this)
    }

    initSpeech = async (info) => {
        let initialText = '';

        if (info.identifier === 'remarks') {
            initialText = this.state.remarks != undefined ? this.state.remarks : '';
        }
        if (info.identifier === 'current_pregnancy_remarks') {
            initialText = this.state.current_pregnancy_remarks != undefined ? this.state.current_pregnancy_remarks : '';
        }
        if (info.identifier === 'mother_remarks') {
          initialText = this.state.mother_remarks != undefined ? this.state.mother_remarks : '';
        }
        if (info.identifier === 'sexual_remarks') {
          initialText = this.state.sexual_remarks != undefined ? this.state.sexual_remarks : '';
        }

        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        } else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                setTimeout(() => {
                    this.setState({
                        speech: {
                            identifier: info.identifier,
                            state: true,
                            initialText: initialText
                        }
                    }, () => {
                        speechService.init(this.state.speech);
                    });

                }, 1000);
            });
        }
    }

    setDefaultValue = (name) => {
        if (name == "risk_factors") {
            const risk_factor = this.state.initialData.risk_factors != null && this.state.initialData.risk_factors.length > 0 ? this.state.initialData.risk_factors.split(',').map(item => { return parseInt(item) }) : [];
            return risk_factor;
        }

        if (name == "pregnant") {
            const pregnant = this.state.initialData.pregnant != null ? this.state.initialData.pregnant : null;
            return pregnant;
        }

        if (name == "is_checked_aph") {
            const is_checked_aph = this.state.initialData.is_checked_aph != null ? this.state.initialData.is_checked_aph : null;
            return is_checked_aph;
        }

        if (name == "lactating") {
            const lactating = this.state.initialData.lactating != null ? this.state.initialData.lactating : null;
            return lactating;
        }
    }

    disabledDate = (current) => {
        const eddByLMP = moment(this.state.initialData.edd_by_lmp, "YYYY-MM-DD");
        const minDate = moment(eddByLMP).subtract(4, 'weeks');
        const maxDate = moment(eddByLMP).add(4, 'weeks');
        return current && (current < minDate || current > maxDate)
    }

    disabledCorrectedEddDate = (current) => {
        // Disable dates greater than the calculated future date
        // return current && current > this.state.corrected_edd_disabled_date;
        const eddByLMP = moment(this.state.initialData.edd_by_lmp, "YYYY-MM-DD");
        const minDate = moment(eddByLMP).subtract(4, 'weeks');
        const maxDate = moment(eddByLMP).add(4, 'weeks');
        return current && (current < minDate || current > maxDate)
    };
    navigateCorrectedEddDate = () => {
      const eddByLMP = moment(this.state.initialData.edd_by_lmp, "YYYY-MM-DD");
      if(moment(eddByLMP) == "Invalid date"){
        return moment()
      }
      else{
        return moment(eddByLMP)
      }
    }

    disabledLmpDate(current) {
        return current && current > moment();
    }

    handlePhysicalExamDataChange = async (e, type) => {
        const { physicalExam } = this.state;
    
        const obj = {
            [type]: e.target.value,
        };
    
        await this.setState({ physicalExam: { ...physicalExam, ...obj } });
        
        // Assuming you have a separate state for physicalExamChecked
        await this.setState({ physicalExamChecked: true });
    
        this.props.setPhysicalExamData(this.state.physicalExam);
    }
    
    handleFetusChange = async (no_of_fetus) => {
        this.setState({ no_of_fetus });
        const { multipleFetalParams } = this.state
        const fetalParams = [...multipleFetalParams]
        const previous_no_of_fetus = multipleFetalParams.length;
        if (no_of_fetus >= 1 && no_of_fetus <= 9) {
            if (no_of_fetus > previous_no_of_fetus) {
                for (let i = 0; i < no_of_fetus - previous_no_of_fetus; i++) {
                    const obj = { id: null, physical_examination_fetus_id: null, fetalMovement: null, fetalHeartRate: '', presentation: null, relPPtoBrim: null, headCc: '', abdominalCc: '', femurLength: '' }

                    fetalParams.push(obj)
                }
            } else if (previous_no_of_fetus > no_of_fetus) {
                let removes = previous_no_of_fetus - no_of_fetus;
                let index = previous_no_of_fetus - 1;

                for (let i = 0; i < removes; i++) {
                    fetalParams.pop();
                    index--;
                }
            }
        }
        else{
            fetalParams = []
        }

        await this.setState({ multipleFetalParams: [...fetalParams] });

        this.props.setMultipleFetalParams(this.state.multipleFetalParams)
    }

    handleFetalParamsChange = async (e, index) => {
        const { multipleFetalParams } = this.state

        const obj = {
            [e.target.name]: e.target.value
        }

        const newArray = [...multipleFetalParams];
        newArray[index] = { ...newArray[index], ...obj };

        await this.setState({ multipleFetalParams: newArray })
        // Call back fucntion to return multiple Fetal Params
        this.props.setMultipleFetalParams(this.state.multipleFetalParams)
    }

    handleFetalParamsSelectChange = async (value, index, key) => {
        const { multipleFetalParams } = this.state;

        const obj = {
            [key]: value
        };

        const newArray = [...multipleFetalParams];
        newArray[index] = { ...newArray[index], ...obj };

        await this.setState({ multipleFetalParams: newArray });

        // Call back function to return multiple select  Fetal Params
        this.props.setMultipleFetalParams(this.state.multipleFetalParams);
    };

    getListValue(selectedValue, OptionObj = null) {
        if (selectedValue == 0 || selectedValue === null) {
            return "-";
        } else if (OptionObj) {
            return OptionObj[selectedValue];
        } else {
            return selectedValue;
        }
    }


    showPhysicalExamFPData() {
        const fetalParamsPrevData = this.state.fetalParamsPrevData;
        if (fetalParamsPrevData && fetalParamsPrevData?.fetal_params.length > 0) {
            return fetalParamsPrevData?.fetal_params.map((data, i) => (
                <tr key={i}>
                    <td>F{i + 1}</td>
                    <td className="text-center">{this.getListValue(data.fetalMovement, FETAL_MOVEMENT)}</td>
                    <td className="text-center">{data.fetalHeartRate || '-'}</td>
                    <td className="text-center">{this.getListValue(data.presentation, PRESENTATION)}</td>
                    <td className="text-center">{this.getListValue(data.relPPtoBrim, RELATIONSHIP_TO_PP_BRIM)}</td>
                    <td className="text-center">{data.headCc || '-'}</td>
                    <td className="text-center">{data.abdominalCc || '-'}</td>
                    <td className="text-center">{data.femurLength || '-'}</td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan="8">No data available</td>
                </tr>
            );
        }
    }
    // componentDidUpdate(prevProps, prevState){
    //   if(this.props?.vitalsData?.V002 && prevProps?.physicalExamData?.weight != this.props?.vitalsData?.V002){
    //     this.updateVitals();
    //   }
    //   if(this.props?.vitalsData?.V009 && prevState?.physicalExam?.pulse_rate != this.props?.vitalsData?.V009){
    //     this.updateVitals();
    //   }
    // }

    async updateVitals(){
      const { physicalExam } = this.state;
      var obj = {}
      if((this.props?.vitalsData?.V005 && this.props?.vitalsData?.V005 > 0) || (this.props?.vitalsData?.V006 && this.props?.vitalsData?.V006 > 0)){
        obj.bpmm = this.props?.vitalsData?.V006+'/'+this.props?.vitalsData?.V005
        this.formRef.current.setFieldsValue({
          bpmm: this.props?.vitalsData?.V006+'/'+this.props?.vitalsData?.V005
        });
      }

      if(this.props?.vitalsData?.V009 && this.props?.vitalsData?.V009 > 0){
        obj.pulse_rate = this.props?.vitalsData?.V009
        this.formRef.current.setFieldsValue({
          pulserate: this.props?.vitalsData?.V009
        });
      }

      if(this.props?.vitalsData?.V002 && this.props?.vitalsData?.V002 > 0){
        obj.weight = this.props?.vitalsData?.V002
        this.formRef.current.setFieldsValue({
          weight: this.props?.vitalsData?.V002
        });
      }
      await this.setState({ physicalExam: { ...physicalExam, ...obj } });
      await this.props.setPhysicalExamData(this.state.physicalExam);
    }

    async obstetricList(){
      const PARAMS = {
        patient_id: LS_SERVICE.get('call_patient_id'),
        doctor_id: LS_SERVICE.get("staff_id"),
        reference_id: LS_SERVICE.get('call_patient_qms_token_id'),
        reference_type: LS_SERVICE.get('reference_type')
      }
      await Axios.post(OBSTETRIC_HISTORY_LIST, PARAMS)
      .then(success => {
        console.log(success.data);
        if (success?.data?.status == true) {
          if (success.data.data.length > 0) {
            let obs_history_list = [];
            success.data.data.forEach(ele => {
              let obj = { id: ele.id, pregnancy_year: ele.year, mode_of_conception: ele.mode, mode_text: this.state.conception_options.filter(x => x.val == ele.mode)[0].label, pregnancy_type: ele.pregnancyType, previous_births: ele.previous_births, indication: ele.indication, remarks: ele.remarks };

              if (ele.pregnancyType == '2') {
                obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label + '(' + ele.previous_births.length + ')';
                let prev_del_types = [];
                let prev_outcomes = [];
                ele.previous_births.forEach(el => {
                  if (el.deliveryType) {
                    let ob_del = { delivery_type: el.deliveryType, count: 1 };
                    let index_del = prev_del_types.findIndex(m => m.delivery_type === el.deliveryType);
                    if (index_del === -1) {
                      prev_del_types.push(ob_del)
                    } else {
                      prev_del_types[index_del].count = prev_del_types[index_del].count + 1;
                    }
                  }

                  let ob_outcome = { outcome: el.outcome, count: 1 };
                  let index_outcome = prev_outcomes.findIndex(m => m.outcome === el.outcome);
                  if (index_outcome === -1) {
                    prev_outcomes.push(ob_outcome)
                  } else {
                    prev_outcomes[index_outcome].count = prev_outcomes[index_outcome].count + 1;
                  }
                });
                let delivery_type_text_arr = [];
                prev_del_types.forEach(e => {
                  delivery_type_text_arr.push(this.state.delivery_options.filter(x => x.val == e.delivery_type)[0].label + '(' + e.count + ')');
                });
                let outcome_text_arr = [];
                prev_outcomes.forEach(e => {
                  outcome_text_arr.push(this.state.outcome_options.filter(x => x.val == e.outcome)[0].label + '(' + e.count + ')');
                });

                obj.delivery_type_text = delivery_type_text_arr.join(',');
                if (obj.delivery_type_text == '') {
                  obj.delivery_type_text = '-';
                }
                obj.outcome_text = outcome_text_arr.join(',');
              } else {
                obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label;
                if (ele.previous_births[0]?.deliveryType) {
                  obj.delivery_type_text = this.state.delivery_options.filter(x => x.val == ele.previous_births[0].deliveryType)[0].label;
                } else {
                  obj.delivery_type_text = '-';
                }
                obj.outcome_text = this.state.outcome_options?.filter(x => x.val == ele.previous_births[0]?.outcome)[0]?.label;
              }
              obs_history_list.push(obj);
            });
            this.setState({ obsHistoryList: obs_history_list });
          }
        }
      }).catch(err => console.log(err));
    }

    numberValidation(value) {
        const isInteger = /^[0-9]+$/g;
        if (value === '' || isInteger.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    menarcheAgeHandler = async (e) => {
        const { initialData, menarch_age } = this.state;
        let check = await this.numberValidation(e.target.value);
        if (check == true) {
            const data = {
                ...initialData,
                menarch_age: e.target.value,
            };

            this.setState({
                initialData: data,
                menarch_age: menarch_age,
            })
            this.props.setCycleLength(data);
        }
    }

    onChangeRadioCheck = async (event) => {
        const { initialData } = this.state;
        const data = {
            ...initialData,
            sexually_active: event.target.value,
        };

        await this.setState({
            initialData: data,
        });

        this.props.setCycleLength(data);
    }

    handleChangeInput = async (event, type) => {
        const { initialData } = this.state;
        if (type === "since" || type === "since_by") {
            const data = {
                ...initialData,
                [event.target.name]: event.target.value
            };
            await this.setState({
                initialData: data,
            });
            this.props.setCycleLength(data);
        }
    }

    handleChangeContraceptiveRemarks = async (e) => {
        const { initialData, contraceptive_detail } = this.state;
        const data = {
            ...initialData,
            contraceptive_detail: e.target.value,
        };
        await this.setState({
            initialData: data,
        });

        this.props.setCycleLength(data);
    }

    onChangeCyclecharacteristicsSelect = async (value, name) => {
        const { initialData, regularity } = this.state;

        const data = {
            ...initialData,
            regularity: value,
        };
        await this.setState({
            initialData: data,
        });

        this.props.setCycleLength(data)
    }

    remarksHandler = async (e) => {
        const { initialData, menstrual_remarks } = this.state;

        const data = {
            ...initialData,
            menstrual_remarks: e.target.value,
        };
        await this.setState({
            initialData: data,
        });

        this.props.setCycleLength(data)
    }

    onChangeBleedingDays = async (e) => {
        const { initialData, duration_of_period } = this.state;

        const data = {
            ...initialData,
            duration_of_period: e.target.value,
        };
        await this.setState({
            initialData: data,
        });

        this.props.setCycleLength(data)
    }

    handleMarriedLifeChange = async (e) => {
        const { initialData, details } = this.state;
        const data = {
            ...initialData,
            details: e.target.value,
        };
        await this.setState({
            initialData: data,
        });
        this.props.setCycleLength(data)
    }

    handleContraceptivesChange = async (e, val) => {
        try {
            let updatedTestDone = [];
            if (val === '1') { // If 'None' checkbox is selected
                updatedTestDone = e.target.checked ? [val] : [];
            } else {
                updatedTestDone = Array.isArray(this.state.initialData.contraceptives)
                    ? [...this.state.initialData.contraceptives]
                    : [];
                if (e.target.checked) {
                    updatedTestDone.push(val);
                } else {
                    updatedTestDone = updatedTestDone.filter(item => item !== val);
                }
                // If 'None' was previously selected, unselect it
                updatedTestDone = updatedTestDone.filter(item => item !== '1');
            }

            // Update initialData
            const { initialData } = this.state;
            const data = {
                ...initialData,
                contraceptives: updatedTestDone,
            };
            // Update the state with the new test_done array
            this.setState({ initialData: data, contraceptives: updatedTestDone });

            this.props.setCycleLength(data);
        } catch (error) {
            console.error("Error occurred in handleContraceptivesChange:", error);
        }
    }

    render() { 
        const { vitalsData, no_of_fetus, multipleFetalParams,contraceptivesList, sinceByList,      contraceptives
        } = this.state;
        const textareaSizeDefault = { minRows: 3, maxRows: 6 };
        const { initialData, ss_configuration, consult_type, obsGyaneData, fetalParamsPrevData, physicalExamPrevData} = this.state;
        const lmpValue = obsGyaneData && obsGyaneData.length > 0 ? obsGyaneData[0].lmp : initialData.lmp;
        const correctedEddValue = obsGyaneData && obsGyaneData.length > 0 ? obsGyaneData[0].corrected_edd : initialData.corrected_edd;
        const eddByLMPvalue = obsGyaneData && obsGyaneData.length > 0 ? obsGyaneData[0].edd_by_lmp : initialData.edd_by_lmp;
        const testDoneValue = this.state.initialData.test_done;
        const contrapTypeCheckedValue = this.state.initialData.contraceptives;
        const anyPreviousComplicationValue = this.state.initialData.any_previous_complication;
        const remarksValue = obsGyaneData && obsGyaneData.length > 0 ? obsGyaneData[0].remarks : initialData.remarks;
        const currentPregnancyRemarksValue = obsGyaneData && obsGyaneData.length > 0 ? obsGyaneData[0].current_pregnancy_remarks : initialData.current_pregnancy_remarks;
        const remarksMotherValue = obsGyaneData && obsGyaneData.length > 0 ? obsGyaneData[0].mother_remarks : initialData.mother_remarks;
        const remarksSexualValue = obsGyaneData && obsGyaneData.length > 0 ? obsGyaneData[0].sexual_remarks : initialData.sexual_remarks;
      
        return (
            <>
                <div className="card-body  gynae_body p-1 emrfrm">
                    <div className='container-fluid medicines  med-cont pb-2'>
                        <Form
                            layout='vertical'
                            className='emrfrm'
                            ref={this.formRef}
                        >
                            {
                                ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 21 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 22 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                            ?
                                <div className='row d-flex align-items-center mb-4'>
                                    <div className='col-auto pr-3'>
                                        <h6 className='text-primary mb-0'>Menstrual History</h6>
                                    </div>
                                        <div className='col-2'>
                                            <div className='antInputItem hasdata'>
                                                <Form.Item
                                                    name="menarch_age"
                                                    label="Menarch Age"
                                                    rules={[{
                                                        type: 'number',
                                                        // max:this.state.dob,
                                                        // message:`Menarch age cannot be greater than patient's age`,
                                                        transform: function (value) {
                                                        return value === "" || value === undefined ? value : +value
                                                    }
                                                }]}
                                                >
                                                    <Input
                                                        addonAfter="Years"
                                                        name="menarch_age"
                                                        id="menarch_age"
                                                        maxLength="2"
                                                        type="text"
                                                        value={this.state.initialData.menarch_age}
                                                        defaultValue={this.state.initialData.menarch_age}
                                                        onChange={(e) =>
                                                            this.menarcheAgeHandler(e)
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='col-2'>
                                            <div className={`antInputItem hasdata`} style={{}}>
                                                <Form.Item
                                                    label="Regularity"
                                                    name="regularity"

                                                >
                                                    <Select
                                                        name="regularity"
                                                        defaultValue={this.state.initialData.regularity}
                                                        value={this.state.initialData.regularity}
                                                        onChange={(e) =>
                                                            this.onChangeCyclecharacteristicsSelect(
                                                                e,
                                                                "regularity"
                                                            )
                                                        }
                                                    >
                                                        <Option value="regular">
                                                            Regular
                                                        </Option>
                                                        <Option value="irregular">
                                                            Irregular
                                                        </Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                </div>
                            :
                                null
                            }

                            <div className='row d-flex align-items-center mb-4'>
                                {
                                    ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 22 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                        ?
                                        <div className='col-2'>
                                            <div className='antInputItem hasdata'>
                                                <Form.Item
                                                    name="lmp"
                                                    className='mb-0'
                                                    label="LMP"
                                                >
                                                    <DatePicker
                                                        name="lmp"
                                                        format="DD MMM, YYYY"
                                                        className='mb-0'
                                                        style={{
                                                            width: "100%",
                                                            height: "34px",
                                                        }}
                                                        defaultValue={lmpValue ? moment(lmpValue, "YYYY-MM-DD") : null}
                                                        onChange={(date, dateString) => this.handleCycleLengthChange(dateString, "lmp")}
                                                        disabledDate={this.disabledLmpDate}
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className='col-2'>
                                    <div className={`antInputItem hasdata`} style={{}}>
                                        <Form.Item
                                            name="bleeding_days"
                                            label="Bleeding Days"
                                            rules={[{
                                                type: 'number',
                                                max: 99,
                                                message:"'Duration of Period' cannot be greater than 99",
                                                transform: function (value) {
                                                return value === "" || value === undefined ? value : +value
                                               }
                                            }]}
                                        >
                                            <Input
                                                type="text"
                                                name="bleeding_days"
                                                id="bleeding_days"
                                                maxLength="2"
                                                defaultValue={this.state.initialData.duration_of_period}
                                                onChange={(e) =>
                                                    this.onChangeBleedingDays(
                                                        e,
                                                        "duration_of_period"
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                               { ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 21 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                    ? 
                                <div className='col-2'>
                                    <div className='antInputItem hasdata'>
                                        <Form.Item name={'cycle_length'} label={'Cycle Length (Days)'} rules={[{
                                            type: 'number',
                                            max: 99,
                                            message: "'Cycle Length' cannot be greater than 99",
                                            transform: function (value) {
                                                return value === "" || value === undefined ? value : +value
                                            }
                                        }]} >
                                            <Input
                                                id={'cycle_length'}
                                                name={'cycle_length'}
                                                autoComplete="off"
                                                maxlength="2"
                                                defaultValue={this.state.initialData.cycle_length}
                                                onChange={(e) => this.handleCycleLengthChange(e, "cycle_length")}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                : null}
                                 <div className='col'>
                                    <div className='antInputItem hasdata'>
                                    <Form.Item
                                        label="Menstrual Remarks"
                                        name="menstrual_remarks"
                                    >
                                         <TextArea
                                            id="menstrual_remarks"
                                            name="menstrual_remarks"
                                            style={{ overflow: "hidden" }}
                                            oninput="auto_height(this)"
                                            onInput={(e) => this.autoHeight(e)}
                                            placeholder='Pain and discomfort'
                                            autoComplete="off"
                                            onChange={(e) =>
                                                this.remarksHandler(e)
                                            }
                                            defaultValue={this.state.initialData.menstrual_remarks}
                                        ></TextArea>
                                    </Form.Item>
                                    </div>
                                </div>
                            </div>
                                    <div className='row d-flex justify-content-between align-items-center'>
                                    <div className='col-10'>
                                    <h6 className='text-primary mb-0'>Sexual History</h6>
                                    </div>
                                    <div className='col-auto'>
                                    <Checkbox
                                            name="is_sexual_history_data_confidential"
                                            checked={this.state.initialData.is_sexual_history_data_confidential === '1'}
                                            onChange={e => this.handleSexualDataConfidentialChange(e)}
                                        >
                                            <span className='text-secondary font-weight-bold'>Confidential</span>
                                        </Checkbox>
                                    </div>
                                    </div>
                                    <div className="row mt-2">
                                    <div className="col-auto mr-3 pt-2">
                                        <span className="pt-0 d-block font-cus">
                                            Sexually Active 
                                        </span>
                                    </div>
                                    <div className="col-auto pt-2">
                                        <div className="custom-control custom-radio custom-control-inline mr-5">
                                            <input
                                                type="radio"
                                                id="yes-sexually"
                                                name="sexually_active"
                                                className="custom-control-input"
                                                checked={this.state.initialData.sexually_active==='Yes'}
                                                value="Yes"
                                                onChange={(e) => this.onChangeRadioCheck(e)}
                                            />
                                            <label
                                                className="custom-control-label pl-2"
                                                htmlFor="yes-sexually"
                                            >
                                                Yes
                                            </label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                id="no-sexually"
                                                name="sexually_active"
                                                className="custom-control-input"
                                                checked={this.state.initialData.sexually_active==='No'}
                                                value="No"
                                                onChange={(e) => this.onChangeRadioCheck(e)}
                                            />
                                            <label
                                                className="custom-control-label pl-2"
                                                htmlFor="no-sexually"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </div>

                                <div className="col-auto">
                                    <div className={this.state.since ? 'form-label-group input-group hasdata' : 'form-label-group input-group'} >
                                        <input
                                            type="text"
                                            className="form-control onlynumbers"
                                            // autoComplete="off"
                                            id="since-sexual"
                                            placeholder="Since"
                                            maxLength="2"
                                            name="since"
                                            style={{ height: "calc(2rem + 2px)" }}
                                            defaultValue={this.state.initialData.since}
                                            value={this.state.initialData.since}
                                            onChange={(e) => this.handleChangeInput(e, 'since')}
                                        />
                                        <span className="input-group-addon max-120"
                                            style={{ padding: "0px" }}>
                                            <select className="custom-select" value={this.state.initialData.since_by} defaultValue={this.state.initialData.since_by} name="since_by" onChange={(e) => this.handleChangeInput(e, 'since_by')}>
                                                {sinceByList !== undefined && sinceByList.length > 0 ? sinceByList.map(d => (
                                                    <option key={d.id} value={d.id} label={d.desc}>{d.desc}</option>
                                                )) : null}
                                            </select>
                                        </span>
                                        <label
                                            htmlFor="since-sexual"
                                            className="pt-0"
                                        >
                                            Since</label>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className={`antInputItem hasdata`}>
                                        <Form.Item
                                            label="Married Life"
                                            name="details"
                                        >
                                            <Input.TextArea
                                                id="s_remarks"
                                                name="details"
                                                style={{ overflow: "hidden" }}
                                                // autoSize={textareaSizeDefault}
                                                oninput="auto_height(this)"
                                                onInput={(e) => this.autoHeight(e)}
                                                defaultValue={this.state.initialData.details}
                                                value={this.state.initialData.details}
                                                onChange={(e) => this.handleMarriedLifeChange(e)}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="row my-4">
                                <div className="col-auto d-flex align-items-center pr-3">
                                    Contraceptives
                                </div>
                                <div className='row d-flex align-items-center'>

                                    <div className='col-auto pr-4'>
                                        <Checkbox
                                            name="none"
                                            checked={contrapTypeCheckedValue?.includes('1')}
                                            onChange={e => this.handleContraceptivesChange(e, '1')}                                                >
                                            None
                                        </Checkbox>
                                    </div>
                                    <div className='col-auto pr-4'>
                                        <Checkbox
                                            name="condom"
                                            checked={contrapTypeCheckedValue?.includes('2')}
                                            onChange={e => this.handleContraceptivesChange(e, '2')}
                                            disabled={contrapTypeCheckedValue?.includes('1')}
                                        >
                                            Condom
                                        </Checkbox>
                                    </div>
                                    <div className='col-auto pr-4'>
                                        <Checkbox
                                            name="iud"
                                            checked={contrapTypeCheckedValue?.includes('3')}
                                            onChange={e => this.handleContraceptivesChange(e, '3')}
                                            disabled={contrapTypeCheckedValue?.includes('1')}
                                        >
                                            IUD
                                        </Checkbox>
                                    </div>
                                    <div className='col-auto pr-4'>
                                        <Checkbox
                                            name="ocp"
                                            checked={contrapTypeCheckedValue?.includes('4')}
                                            onChange={e => this.handleContraceptivesChange(e, '4')}
                                            disabled={contrapTypeCheckedValue?.includes('1')}
                                        >
                                            OCP
                                        </Checkbox>
                                    </div>
                                    <div className='col-auto pr-4'>
                                        <Checkbox
                                            name="hormonal"
                                            checked={contrapTypeCheckedValue?.includes('5')}
                                            onChange={e => this.handleContraceptivesChange(e, '5')}
                                            disabled={contrapTypeCheckedValue?.includes('1')}
                                        >
                                            Hormonal
                                        </Checkbox>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className={this.state.contraceptive_detail ? 'form-group micwrap ant-mic-wrap hasdata mb-0' : 'form-group micwrap ant-mic-wrap mb-0'}>
                                        <Input.TextArea
                                            className="form-control speech-field"
                                            rows="1"
                                            placeholder="Contraceptive Remarks"
                                            id="Contraceptive"
                                            // maxlegth="255"
                                            oninput="auto_height(this)"
                                            name="contraceptive_detail"
                                            value={this.state.initialData.contraceptive_detail}
                                            defaultValue={this.state.initialData.contraceptive_detail}
                                            onChange={(e) => this.handleChangeContraceptiveRemarks(e)}

                                        />
                                        <label htmlFor="contraceptive-detail-sexual">
                                            Contraceptive Remarks
                                        </label>
                                    </div>
                                </div>

                            </div>
                            {
                                ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 34 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                            ?
                                <div className='row d-flex align-items-center'>
                                    <div className='col'>
                                      <div className={`form-group antInputItem hasdata`}>
                                        <Input.TextArea
                                            className="form-control diagnosis_notes_class speech-field"
                                            id="s_remarks"
                                            name="sexual_remarks"
                                            // autoSize={textareaSizeDefault}
                                            oninput="auto_height(this)"
                                            onInput={(e) => this.autoHeight(e)}
                                            defaultValue={this.state.sexual_remarks != "" ? this.state.sexual_remarks : remarksSexualValue}
                                            onChange={(e) => this.handleCycleLengthChange(e, "sexual_remarks")}
                                        />
                                        <label htmlFor="s_remarks">Remarks </label>
                                      </div>
                                    </div>
                                    <div className='col-auto mb-4 text-right '>
                                        <Checkbox
                                            name="is_sexual_hx_confidential"
                                            checked={this.state.initialData.is_sexual_hx_confidential === '1' ? true : false}
                                            onChange={e => this.handleSexualConfidentialChange(e)}
                                        >
                                            <span className='text-secondary font-weight-bold'>Confidential</span>
                                        </Checkbox>
                                    </div>
                                </div>
                            :
                                null
                            } 
                            {
                                ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 25 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                            ?
                                <div className='row d-flex align-items-center mb-4'>
                                    {
                                        ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                    ?
                                        <>
                                            <div className='col-auto d-flex align-items-center'>
                                                <div className='antInputItem hasdata'>
                                                    <label className="d-md-inline mr-3 mb-0">Pregnant</label>
                                                    <div className="mr-2 d-md-inline">
                                                        <Form.Item
                                                            name="pregnant"
                                                            className='mb-0'
                                                            >
                                                            <Radio.Group name='pregnant' defaultValue={this.setDefaultValue("pregnant") || "0"} // Ensure "0" is set as the default value
                                                                onChange={(e) => this.handleCycleLengthChange(e, "pregnant")}  // Handle changes
                                                            >
                                                                <Radio value="0" name="no">No</Radio>
                                                                <Radio value="1" name="yes">Yes</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        name="edd_by_lmp"
                                                        className='mb-0'
                                                        label="EDD By LMP"
                                                        style={{ display: this.state.initialData.pregnant === "0" ? 'none' : 'block' }}
                                                    >
                                                        <DatePicker
                                                            name="edd_by_lmp"
                                                            format="DD MMM, YYYY"
                                                            className='mb-0'
                                                            style={{
                                                                width: "100%",
                                                                height: "34px",
                                                            }}
                                                            defaultValue={eddByLMPvalue ? moment(eddByLMPvalue, "YYYY-MM-DD") : null}
                                                            onChange={(date, dateString) => this.handleEDDChange(dateString)}
                                                            disabledDate={this.disabledDate}
                                                            allowClear={true} // Set allowClear to false to disable clearing the date
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-2 order-xl-1 order-2  pt-md-2 pt-xl-0'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        name="corrected_edd"
                                                        className='mb-0'
                                                        label="Corrected EDD"
                                                        style={{ display: this.state.initialData.pregnant === "0" ? 'none' : 'block' }}
                                                    >
                                                        <DatePicker
                                                            name="corrected_edd"
                                                            format="DD MMM, YYYY"
                                                            className='mb-0'
                                                            style={{
                                                                width: "100%",
                                                                height: "34px",
                                                            }}
                                                            // defaultPickerValue={this.navigateCorrectedEddDate}
                                                            defaultValue={correctedEddValue ? moment(correctedEddValue, "YYYY-MM-DD") : null}
                                                            disabledDate={this.disabledCorrectedEddDate}  // Use the disabledCorrectedEddDate function
                                                            onChange={(date, dateString) => this.handleCorrecteEddChange(dateString)}
                                                            allowClear={true}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </>
                                    :
                                        null
                                    }
                                    {
                                        ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 25 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                    ?
                                        <div className='col order-xl-2 order-1 text-right'>
                                            <Checkbox
                                                name="lactating"
                                                checked={this.state.initialData.lactating === '1'}
                                                onChange={e => this.handleLactatingChange(e)}
                                            >
                                                Lactating
                                            </Checkbox>
                                        </div>
                                    :
                                        null
                                    }
                                </div>
                            : 
                                null
                            }
                            {
                                this.state.initialData.pregnant === "1" && (ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
                            ?
                                <div className='row d-flex align-items-center'>
                                    <div className='col-auto pr-4'>
                                        <h6 className='text-primary mb-0 pt-1' style={{ display: this.state.initialData.pregnant === "0" ? 'none' : 'block' }}>Confirmed by</h6>
                                    </div>
                                    <div className='col-auto pr-3' style={{ display: this.state.initialData.pregnant === "0" ? 'none' : 'block' }}>
                                        <Checkbox
                                            name="upt"
                                            checked={testDoneValue?.includes('1')}
                                            onChange={e => this.handleTestDoneChange(e, '1')}                                                >
                                            UPT
                                        </Checkbox>
                                    </div>
                                    <div className='col-auto pr-3' style={{ display: this.state.initialData.pregnant === "0" ? 'none' : 'block' }}>
                                        <Checkbox
                                            name="serumhcg"
                                            checked={testDoneValue?.includes('2')}
                                            onChange={e => this.handleTestDoneChange(e, '2')}
                                        >
                                            Serum HCG
                                        </Checkbox>
                                    </div>
                                    <div className='col-auto pr-3' style={{ display: this.state.initialData.pregnant === "0" ? 'none' : 'block' }}>
                                        <Checkbox
                                            name="usg"
                                            checked={testDoneValue?.includes('3')}
                                            onChange={e => this.handleTestDoneChange(e, '3')}
                                        >
                                            USG
                                        </Checkbox>
                                    </div>
                                    <div className='col-auto' style={{ display: this.state.initialData.pregnant === "0" ? 'none' : 'block' }}>
                                        <Checkbox
                                            name="home"
                                            checked={testDoneValue?.includes('4')}
                                            onChange={e => this.handleTestDoneChange(e, '4')}
                                        >
                                            Home Pregnancy Test
                                        </Checkbox>
                                    </div>
                                </div>
                            :
                                null
                            }

                            {
                                ((this.state.initialData.pregnant === "1" && (ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) ) || ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 30 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
                            ?
                                <div className='row d-flex align-items-center mb-4'>
                                    {
                                        this.state.initialData.pregnant === "1" && (ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) 
                                    ?
                                        <div className='col-6'>
                                            <div className='row d-flex align-items-center'>
                                                <div className='col-12'>
                                                    <h6 className='text-primary' >Gestational Age</h6>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-auto' >
                                                    <label>By LMP</label>
                                                </div>
                                                <div className='col-2'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="Weeks"
                                                            name="gestational_age_lmp_weeks"
                                                        >
                                                            <Input
                                                                id="weeks"
                                                                name="gestational_age_lmp_weeks"
                                                                maxlength="2"
                                                                defaultValue={this.state.initialData.gestational_age_lmp_weeks}
                                                                onChange={(e) => this.handleLmpChange(e, "gestational_age_lmp_weeks")}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-2'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="Days"
                                                            name="gestational_age_lmp_days"
                                                            rules={[
                                                              { pattern: /^(0?)([1-6]{0,1})$/, message: 'Not allowed!' }
                                                            ]}
                                                        >
                                                            <Input
                                                                id="days"
                                                                name="gestational_age_lmp_days"
                                                                maxlength="1"
                                                                defaultValue={this.state.initialData.gestational_age_lmp_days}
                                                                onChange={(e) => this.handleLmpChange(e, "gestational_age_lmp_days")}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-auto pl-4' >
                                                    <label>By USG</label>
                                                </div>
                                                <div className='col-2'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="Weeks"
                                                            name="gestational_age_usg_weeks"
                                                        >
                                                            <Input
                                                                id="weeks"
                                                                name="gestational_age_usg_weeks"
                                                                maxLength="2"
                                                                defaultValue={this.state.initialData.gestational_age_usg_weeks}
                                                                onChange={(e) => this.handleCycleLengthChange(e, "gestational_age_usg_weeks")}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-2'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="Days"
                                                            name="gestational_age_usg_days"
                                                            rules={[
                                                              { pattern: /^(0?)([1-6]{0,1})$/, message: 'Not allowed!' }
                                                            ]}
                                                        >
                                                            <Input
                                                                id="days"
                                                                name="gestational_age_usg_days"
                                                                maxLength="1"
                                                                defaultValue={this.state.initialData.gestational_age_usg_days}
                                                                onChange={(e) => this.handleCycleLengthChange(e, "gestational_age_usg_days")}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        null
                                    }

                                    {
                                        ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 30 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                    ?
                                        <div className={this.state.initialData.pregnant === "1" ? "col-6" : 'col-6'}>
                                            <div className='row '>
                                                <div className='col-4'>
                                                    <h6 className='text-primary mb-0'>Obstetric History</h6>
                                                </div>
                                                <div className='col-4 text-center'>
                                                    {
                                                      this.state.obsHistoryList?.length > 0 
                                                    ?
                                                      <a className='text-secondary font-weight-bold' onClick={() => { this.setState({ obsHistoryListPopupVisible: true }) }}><u>Previous Pregnancy Details</u></a>
                                                    :
                                                      null
                                                    }
                                                </div>
                                                <div className='col-4 text-right'>
                                                    <Checkbox
                                                        name="is_confidential"
                                                        checked={this.state.initialData.is_confidential === '1'}
                                                        onChange={e => this.handleConfidentialChange(e)}
                                                    >
                                                        <span className='text-secondary font-weight-bold'>Confidential</span>
                                                    </Checkbox>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className={this.state.initialData.pregnant === "1" ? "col-12" : 'col-12'}>
                                                    <div className='card obs-hiscard mb-0'>
                                                        <div className='row'>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Gravidity"
                                                                        name="gravidity"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="gravidity"
                                                                            name="gravidity"
                                                                            // type="text"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.gravidity}
                                                                            onChange={(e) => this.handleGPALEChange(e, "gravidity")}  // Handle changes
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Parity"
                                                                        name="parity"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="parity"
                                                                            name="parity"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.parity}
                                                                            onChange={(e) => this.handleGPALEChange(e, "parity")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Abortion"
                                                                        name="abortion"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="abortion"
                                                                            name="abortion"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.abortion}
                                                                            onChange={(e) => this.handleGPALEChange(e, "abortion")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Live Birth"
                                                                        name="live_birth"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="live_birth"
                                                                            name="live_birth"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.live_birth}
                                                                            onChange={(e) => this.handleGPALEChange(e, "live_birth")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Ectopic"
                                                                        name="ectopic"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="ectopic"
                                                                            name="ectopic"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.ectopic}
                                                                            onChange={(e) => this.handleGPALEChange(e, "ectopic")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                           
                                                           
                                                        </div>
                                                        <div className='row mt-3'>
                                                        <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="LSCS"
                                                                        name="lscs"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="lscs"
                                                                            name="lscs"
                                                                            // type="text"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.lscs}
                                                                            onChange={(e) => this.handleGPALEChange(e, "lscs")}  // Handle changes
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-auto'>
                                                              
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Miscarriage"
                                                                        name="miscarriage"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="miscarriage"
                                                                            name="miscarriage"
                                                                            // type="text"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.miscarriage}
                                                                            onChange={(e) => this.handleGPALEChange(e, "miscarriage")}  // Handle changes
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="MTP"
                                                                        name="mtp"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="mtp"
                                                                            name="mtp"
                                                                            // type="text"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.mtp}
                                                                            onChange={(e) => this.handleGPALEChange(e, "mtp")}  // Handle changes
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Preterm"
                                                                        name="preterm"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="preterm"
                                                                            name="preterm"
                                                                            // type="text"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.preterm}
                                                                            onChange={(e) => this.handleGPALEChange(e, "preterm")}  // Handle changes
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Term"
                                                                        name="term"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="term"
                                                                            name="term"
                                                                            // type="text"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.term}
                                                                            onChange={(e) => this.handleGPALEChange(e, "term")}  // Handle changes
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Still Birth"
                                                                        name="still_birth"
                                                                        rules={[
                                                                            { pattern: /^\d{1,2}$/, message: 'Not allowed!' },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            id="still_birth"
                                                                            name="still_birth"
                                                                            // type="text"
                                                                            maxLength="2"
                                                                            defaultValue={this.state.initialData.still_birth}
                                                                            onChange={(e) => this.handleGPALEChange(e, "still_birth")}  // Handle changes
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                </div>
                            :
                                null
                            }

                            {
                                ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 28 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                            ?
                                <div className='row d-flex align-items-center mb-4'>
                                    <div className='col-12'>
                                        <div className="antSelectItem hasdata">
                                            <Form.Item
                                                label="Pregnancy risk factors"
                                                name="risk_factors"
                                            >
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    id="risk_factors"
                                                    name="risk_factors"
                                                    style={{ width: '100%' }}
                                                    placeholder="Select"
                                                    onChange={(value) => this.handleCycleLengthChange({ target: { value } }, "risk_factors")} // Pass the value directly
                                                    mode="multiple"
                                                    defaultValue={() => this.setDefaultValue("risk_factors")}
                                                    allowClear
                                                    filterOption={(input, option) => {
                                                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0;
                                                    }}
                                                >
                                                    {this.optionRiskFactorsLoad()}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                            }
                            {
                                ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 29 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                            ?
                                <div className='row d-flex align-items-center mb-4'>
                                    <div className='col-12'>
                                        <div className={`form-group antInputItem hasdata`}>
                                            <Form.Item
                                                label="Pregnancy Risk Factor Management Remarks"
                                                name="current_pregnancy_remarks"
                                            >
                                            <Input.TextArea
                                                className="form-control diagnosis_notes_class speech-field"
                                                id="current_pregnancy_remarks"
                                                name="current_pregnancy_remarks"
                                                placeholder="Enter"
                                                autoSize={{ minRows: 1.3, maxRows: 6 }}
                                                maxLength={255}
                                                onInput={(e) => this.autoHeight(e)}
                                                onChange={(e) => this.handleCycleLengthChange(e, "current_pregnancy_remarks")}  // Handle changes
                                                defaultValue={this.state.current_pregnancy_remarks != "" ? this.state.current_pregnancy_remarks : currentPregnancyRemarksValue}

                                            />
                                            </Form.Item>
                                            {/* <label htmlFor="current_pregnancy_remarks">Pregnancy Risk Factor Management Remarks</label> */}
                                        </div>
                                    </div>
                               </div>
                            :
                                null
                            }

                            {
                                ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 32 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                            ?
                                <>
                                    <div className="row d-flex align-items-center mb-4">
                                        <div className='col-auto d-flex align-items-center'>
                                            <h6 className='text-primary mb-0 pr-3'>Any Previous Complication</h6>
                                            <div className="mr-2 d-md-inline">
                                                <Form.Item
                                                name="is_checked_aph"
                                                className='mb-0'
                                                >
                                                    <Radio.Group name='is_checked_aph' defaultValue={this.setDefaultValue("is_checked_aph")}
                                                        onChange={(e) => this.handleRadioChange(e, "is_checked_aph")}  // Handle changes
                                                    >
                                                        <Radio value="0" name="no">No</Radio>
                                                        <Radio value="1" name="yes">Yes</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row align-items-center mb-4' style={{ display: this.state.initialData.is_checked_aph === "1" ? 'block' : 'none' }}>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="aph"
                                                        checked={anyPreviousComplicationValue?.includes('1')}
                                                        // defaultChecked={this.state.any_previous_complication?.includes('1')}    
                                                        onChange={e => this.handleAnyPreviousChange(e, '1')}

                                                    >
                                                        APH
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="ec"
                                                        checked={anyPreviousComplicationValue?.includes('2')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '2')}
                                                    >
                                                        Eclampsia
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="pih"
                                                        checked={anyPreviousComplicationValue?.includes('3')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '3')}
                                                    >
                                                        PIH
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="pph"
                                                        checked={anyPreviousComplicationValue?.includes('4')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '4')}
                                                    >
                                                        PPH
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="anemia"
                                                        checked={anyPreviousComplicationValue?.includes('5')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '5')}
                                                    >
                                                        Anaemia
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="obslab"
                                                        checked={anyPreviousComplicationValue?.includes('6')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '6')}
                                                    >
                                                        Obstructed Labor
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="lscs"
                                                        checked={anyPreviousComplicationValue?.includes('7')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '7')}
                                                    >
                                                        LSCS
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="congano"
                                                        checked={anyPreviousComplicationValue?.includes('8')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '8')}
                                                    >
                                                        Congenital Anamoly
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="abort"
                                                        checked={anyPreviousComplicationValue?.includes('9')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '9')}
                                                    >
                                                        Abortion
                                                    </Checkbox>
                                                </div>
                                                <div className='col-auto pr-4 pb-2'>
                                                    <Checkbox
                                                        name="anyother"
                                                        checked={anyPreviousComplicationValue?.includes('10')}
                                                        onChange={e => this.handleAnyPreviousChange(e, '10')}
                                                    >
                                                        Any Other
                                                    </Checkbox>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </>
                            :
                                null
                            }

                            {
                                ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 33 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                            ?
                                <div className='row d-flex align-items-center mb-4'>
                                    <div className='col-12'>
                                        <div className={`form-group antInputItem hasdata}`}>
                                            <Form.Item
                                                label="Obstetric Remarks"
                                                name="remarks"
                                            >
                                                <Input.TextArea
                                                    className="form-control diagnosis_notes_class speech-field"
                                                    id="remarks"
                                                    name="remarks"
                                                    autoSize={textareaSizeDefault}
                                                    // maxLength={255}
                                                    oninput="auto_height(this)"
                                                    onInput={(e) => this.autoHeight(e)}
                                                    defaultValue={this.state.remarks != "" ? this.state.remarks : remarksValue}
                                                    onChange={(e) => this.handleCycleLengthChange(e, "remarks")}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                            }

                            {
                                this.state.initialData.pregnant === "1" && (ss_configuration && ss_configuration?.some(o => (o?.id === 16 && o?.sub_items?.some(p => ((p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) 
                            ?
                                <>
                                    <div className='row d-flex align-items-center mb-4'>
                                        <div className='col-auto d-flex align-items-center'>
                                            <h6 className='text-primary mb-0 pr-3'>Mother & Fetal Parameters</h6>
                                        </div>
                                        <div className='col-2'>
                                            <div className='antInputItem hasdata'>
                                                <Form.Item
                                                    label="No. of Fetus"
                                                    name="no_of_fetus"
                                                    rules={[
                                                        { required: true, message: 'Please fill No. Of Fetus.' },
                                                        { pattern: /^[1-9]{1,1}$/, message: 'Not allowed!' },
                                                        /* {max: 8, message: 'Max 8 chars.'} */
                                                    ]}

                                                >
                                                    <Input
                                                        id="nofetus"
                                                        name="no_of_fetus"
                                                        onChange={(e) => this.handleFetusChange(e.target.value)}
                                                        defaultValue={no_of_fetus}
                                                    />

                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='col text-right '>
                                            <Checkbox
                                                name="mother_fetal_printwithprescription"
                                                checked={this.state.initialData.mother_fetal_printwithprescription === '1' ? true : false}
                                                onChange={e => this.handleMotherFetalPrintOnPrescriptionConfidentialChange(e)}
                                            >
                                                <span className='text-secondary font-weight-bold'>Print with Prescription</span>
                                            </Checkbox>
                                        </div>
                                    </div>
                                    <div className='row d-flex align-items-center mb-4'>
                                        <div className='col-12'>
                                            <div className='card lightblue-card mb-0'>
                                                <div className='row'>
                                                    <div className='col-7'>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <h6 className='text-primary mb-1'>POG (Weeks)</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="pog_weeks"
                                                                        initialValue={this.state.physicalExam?.pog_weeks}
                                                                    >
                                                                        <Input
                                                                            id="pog"
                                                                            name="pog_weeks"

                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, 'pog_weeks')}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <h6 className='text-primary mb-1'>Weight (Kg)</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="weight"
                                                                    >
                                                                        <Input
                                                                            id="weight"
                                                                            name="weight"
                                                                            defaultValue={this.state.physicalExam?.weight}
                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, 'weight')}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <h6 className='text-primary mb-1'>BP (mmHg)</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="bpmm"
                                                                    >
                                                                        <Input
                                                                            id="bpmm"
                                                                            name="bpmm"
                                                                            defaultValue={this.state.physicalExam?.bpmm}
                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, "bpmm")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h6 className='text-primary mb-1'>Pulse Rate (min)</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="pulserate"
                                                                    >
                                                                        <Input
                                                                            id="pulserate"
                                                                            name="pulserate"
                                                                            defaultValue={this.state.physicalExam?.pulse_rate}
                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, "pulse_rate")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <h6 className='text-primary mb-1'>Fundal Ht (cm)</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="fundal_hgt"
                                                                    >
                                                                        <Input
                                                                            defaultValue={this.state.physicalExam?.fundal_height}
                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, "fundal_height")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-7'>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <h6 className='text-primary mb-1'>Edema</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="edema"
                                                                    >
                                                                        <Input
                                                                            id="edema"
                                                                            name="edema"
                                                                            defaultValue={this.state.physicalExam?.edema}
                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, "edema")} />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <h6 className='text-primary mb-1'>Pallor</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="pallor"
                                                                    >
                                                                        <Input
                                                                            id="pallor"
                                                                            name="pallor"
                                                                            defaultValue={this.state.physicalExam?.pallor}
                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, "pallor")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <h6 className='text-primary mb-1'>Cervix Exam</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="cervix_exam"
                                                                    >
                                                                        <Input
                                                                            name="cervix_exam"
                                                                            defaultValue={this.state.physicalExam?.cervix_exam}
                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, "cervix_exam")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className='row'>
                                                            <div className='col-xl-6 col-8'>
                                                                <h6 className='text-primary mb-1'>Preterm Labour (Sign/Symptoms)</h6>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label=""
                                                                        name="preterm_labour"
                                                                    >
                                                                        <Input
                                                                            id="pretermlabour"
                                                                            name="preterm_labour"
                                                                            defaultValue={this.state.physicalExam?.preterm_labour}
                                                                            onChange={(e) => this.handlePhysicalExamDataChange(e, "preterm_labour")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='card blue-card mb-0'>
                                                {multipleFetalParams.map((item, key) => {
                                                    return (
                                                        <React.Fragment key={key}>
                                                            <div className="row mb-4">
                                                                <div className="col-1 pt-1">
                                                                    F{key + 1}
                                                                </div>
                                                                <div className="col">
                                                                    <div className={'antSelectItem hasdata'}>
                                                                        <Form.Item
                                                                            label="Fetal Movement"
                                                                            preserve={false}
                                                                        >
                                                                            <Select
                                                                                showSearch
                                                                                style={{ width: '100%' }}
                                                                                optionFilterProp="children"
                                                                                value={item.fetalMovement}
                                                                                onChange={(value) => this.handleFetalParamsSelectChange(value, key, 'fetalMovement')}
                                                                            >
                                                                                {FETAL_MOVEMENT !== undefined ? Object.keys(FETAL_MOVEMENT).map((d, i) => (
                                                                                    <Option key={i} value={d} label={FETAL_MOVEMENT[d]}>
                                                                                        {FETAL_MOVEMENT[d]}
                                                                                    </Option>
                                                                                )) : null}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className={'antInputItem hasdata'}>
                                                                        <Form.Item
                                                                            label="Fetal Heart Rate (b/min)"
                                                                            preserve={false}
                                                                        >
                                                                            <Input
                                                                                autoComplete="off"
                                                                                type="text"
                                                                                maxLength="3"
                                                                                name="fetalHeartRate"
                                                                                onChange={(e) => this.handleFetalParamsChange(e, key)}
                                                                                defaultValue={item.fetalHeartRate}
                                                                            />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className={'antSelectItem hasdata'}>
                                                                        <Form.Item
                                                                            label="Presentation"
                                                                            preserve={false}
                                                                        >
                                                                            <Select
                                                                                showSearch
                                                                                style={{ width: '100%' }}
                                                                                optionFilterProp="children"
                                                                                value={item.presentation}
                                                                                onChange={(value) => this.handleFetalParamsSelectChange(value, key, 'presentation')}
                                                                            >
                                                                                {PRESENTATION !== undefined ? Object.keys(PRESENTATION).map((d, i) => (
                                                                                    <Option key={i} value={d} label={PRESENTATION[d]}>
                                                                                        {PRESENTATION[d]}
                                                                                    </Option>
                                                                                )) : null}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className={'antSelectItem hasdata'}>
                                                                        <Form.Item
                                                                          label="Relation of PP to Brim"
                                                                          preserve={false}
                                                                        >
                                                                          <Select showSearch style={{ width: '100%' }}
                                                                              value={item.relPPtoBrim}
                                                                              onChange={(value) => this.handleFetalParamsSelectChange(value, key, 'relPPtoBrim')}
                                                                          >
                                                                              {RELATIONSHIP_TO_PP_BRIM !== undefined ? Object.keys(RELATIONSHIP_TO_PP_BRIM).map((d, i) => (
                                                                                  <Option key={i} value={d} label={RELATIONSHIP_TO_PP_BRIM[d]}>
                                                                                      {RELATIONSHIP_TO_PP_BRIM[d]}
                                                                                  </Option>
                                                                              )) : null}
                                                                          </Select>
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-1 pt-1">
                                                                    &nbsp;
                                                                </div>
                                                                <div className="col">
                                                                    <div className={'antInputItem hasdata'}>
                                                                      <Form.Item
                                                                            label="Head CC (cm)"
                                                                            preserve={false}
                                                                        >
                                                                        <Input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            maxLength="4"
                                                                            name="headCc"
                                                                            defaultValue={item.headCc}
                                                                            onChange={(e) => this.handleFetalParamsChange(e, key)}
                                                                        />
                                                                      </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className={'antInputItem hasdata'}>
                                                                      <Form.Item
                                                                          label="Abdominal CC (cm)"
                                                                          preserve={false}
                                                                      >
                                                                        <Input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder="Abdominal CC (cm)"
                                                                            maxLength="4"
                                                                            name="abdominalCc"
                                                                            defaultValue={item.abdominalCc}
                                                                            onChange={(e) => this.handleFetalParamsChange(e, key)}
                                                                        />
                                                                      </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className={'antInputItem hasdata'}>
                                                                      <Form.Item
                                                                          label="Femur Length (cm)"
                                                                          preserve={false}
                                                                      >
                                                                        <Input
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder="Femur Length (cm)"
                                                                            maxLength="4"
                                                                            name="femurLength"
                                                                            defaultValue={item.femurLength}
                                                                            onChange={(e) => this.handleFetalParamsChange(e, key)}

                                                                        />
                                                                      </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                                <div className='row'>
                                                    <div className="col-12">
                                                        <div className={`form-group antInputItem hasdata`}>
                                                            <Input.TextArea
                                                                className="form-control diagnosis_notes_class speech-field"
                                                                id="m_remarks"
                                                                name="mother_remarks"
                                                                autoSize={textareaSizeDefault}
                                                                oninput="auto_height(this)"
                                                                onInput={(e) => this.autoHeight(e)}
                                                                defaultValue={this.state.mother_remarks != "" ? this.state.mother_remarks : remarksMotherValue}
                                                                onChange={(e) => this.handleCycleLengthChange(e, "mother_remarks")}

                                                            />
                                                            <label htmlFor="m_remarks">Remarks </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.obsGynaePrevData?.pregnant == 1 && physicalExamPrevData?.reference_id && ((physicalExamPrevData?.pog_weeks ||
                                          physicalExamPrevData?.weight ||
                                          physicalExamPrevData?.bpmm ||
                                          physicalExamPrevData?.pulse_rate ||
                                          physicalExamPrevData?.fundal_height ||
                                          physicalExamPrevData?.edema ||
                                          physicalExamPrevData?.pallor || 
                                          physicalExamPrevData?.cervix_exam ||
                                          physicalExamPrevData?.preterm_labour) || (fetalParamsPrevData?.fetal_params?.length > 0))
                                    ?
                                        <div className='row mb-4'>
                                            <h6 className='col-auto text-primary'>Last Visit ({moment(physicalExamPrevData.created_at).format('DD MMM, YYYY')})</h6>
                                            {/* <div className='col text-right'>
                                                <a className='nostyle-link mr-2'>
                                                    <button type="button" class="btn top-chart-btn p-0 mr-2"><i class="icon-chart"></i></button>
                                                </a>
                                                <a href=""><u className='text-secondary'>View All</u></a>
                                            </div> */}
                                        </div>
                                    :
                                        null
                                    }

                                    <div className='row mb-4'>
                                        {
                                            this.state.obsGynaePrevData?.pregnant == 1 && physicalExamPrevData?.reference_id && (physicalExamPrevData?.pog_weeks ||
                                              physicalExamPrevData?.weight ||
                                              physicalExamPrevData?.bpmm ||
                                              physicalExamPrevData?.pulse_rate ||
                                              physicalExamPrevData?.fundal_height ||
                                              physicalExamPrevData?.edema ||
                                              physicalExamPrevData?.pallor || 
                                              physicalExamPrevData?.cervix_exam ||
                                              physicalExamPrevData?.preterm_labour)
                                        ?
                                            <div className='col-12'>
                                                <div className='card lightblue-card mb-0'>
                                                    <div className='row'>
                                                        <div className='col-1'>&nbsp;</div>
                                                        <div className='col-1'>
                                                            <label className='text-primary'>POG (Weeks)</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData?.pog_weeks}</h6>
                                                        </div>
                                                        <div className='col-1'>
                                                            <label className='text-primary'>Weight (Kg)</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData?.weight}</h6>
                                                        </div>
                                                        <div className='col-1'>
                                                            <label className='text-primary'>BP (mmHg)</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData?.bpmm}</h6>
                                                        </div>
                                                        <div className='col-1'>
                                                            <label className='text-primary'>Pulse Rate (min)</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData.pulse_rate}</h6>
                                                        </div>
                                                        <div className='col-1'>
                                                            <label className='text-primary'>Fundal ht (cm)</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData.fundal_height}</h6>
                                                        </div>
                                                        <div className='col-1'>
                                                            <label className='text-primary'>Edema</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData.edema}</h6>
                                                        </div>
                                                        <div className='col-1'>
                                                            <label className='text-primary'>Pallor</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData.pallor}</h6>
                                                        </div>
                                                        <div className='col-1'>
                                                            <label className='text-primary'>Cervix Exam</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData.cervix_exam}</h6>
                                                        </div>
                                                        <div className='col-2'>
                                                            <label className='text-primary'>Preterm Labour (Sign/Symptoms)</label>
                                                            <h6 className='text-primary'>{physicalExamPrevData.preterm_labour}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        : 
                                            null
                                        }
                                        {
                                            this.state.obsGynaePrevData?.pregnant == 1 && fetalParamsPrevData?.fetal_params?.length > 0
                                        ?
                                            <div className='col-12'>
                                              <div className='card blue-card mb-0'>
                                                <table className="table table-bordered custom-plan-header mb-0 mt-1">
                                                    <tbody>
                                                        <tr className="text-default">
                                                            <td className="text-center" style={{ width: "50px" }}></td>
                                                            <td className="text-center"><h6 className='text-primary'>Fetal Movement</h6></td>
                                                            <td className="text-center"><h6 className='text-primary'>Fetal Heart Rate<br/>(b/min)</h6></td>
                                                            <td className="text-center"><h6 className='text-primary'>Presentation</h6></td>
                                                            <td className="text-center"><h6 className='text-primary'>Relation Of PP To Brim</h6></td>
                                                            <td className="text-center"><h6 className='text-primary'>Head CC<br/>(cm)</h6></td>
                                                            <td className="text-center"><h6 className='text-primary'>Abdominal CC<br/>(cm)</h6></td>
                                                            <td className="text-center"><h6 className='text-primary'>Femur Length<br/>(cm)</h6></td>
                                                        </tr>
                                                        {this.showPhysicalExamFPData()}
                                                    </tbody>
                                                </table>
                                              </div>
                                            </div>
                                        :
                                            null
                                        }
                                    </div>
                                </>
                            :
                                null
                            }

                           
                        </Form>
                    </div>
                </div>

                <Modal
                    id="alert_confirm_message"
                    className="alert_confirm_message"
                    visible={this.state.prevRecordedObsGynaePopupVisible}
                    bodyStyle={{ padding: "25px 10px" }}
                    closable={false}
                    width="400px"
                    footer={[
                        <div className="text-center">
                          <button
                            className="btn btn-outline-secondary px-5 cancelOrderSet"
                            onClick={(e) => this.copyPrevRecordedObsGynae(e, 'N')}
                          >
                            NO
                          </button>
                          <button
                            className="btn btn-primary px-5 deleteOrderSet ml-1"
                            id='deleteButton'
                            onClick={(e) => this.copyPrevRecordedObsGynae(e, 'Y')}
                          >
                            YES
                          </button>
                        </div>,
                      ]}
                    >
                    <div className="container-fluid emrfrm">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    The patient was marked pregnant during previous visit. Do you want to copy the pregnancy details from visit date {moment(this.state.obsGynaePrevData?.created_at).format("DD MMM, YYYY")}?
                                </div>
                            </div>
                            {/* <div className="col-12 d-flex justify-content-center mt-2">
                                <Button key="back" className="btn btn-outline-secondary px-4 mr-2" onClick={() => this.setState({ saveAsCareProtocolModal: false })}>
                                CANCEL
                                </Button>
                                <Button
                                key="submit"
                                className="btn btn-primary px-4"
                                type="primary"
                                disabled={this.state.savedisabled}
                                onClick={() => this.templateSubmit()}
                                >
                                SAVE
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </Modal>  
                <Modal
                    id="prev_pregnency_details"
                    className="prev_pregnency_details"
                    visible={this.state.obsHistoryListPopupVisible}
                    bodyStyle={{ padding: "25px 10px" }}
                    closable={false}
                    width="1000px"
                    footer={[
                      <div className="text-center">
                        <button
                          className="btn btn-outline-secondary px-5 cancelOrderSet"
                          onClick={(e) => this.closePopup(e, ['obsHistoryList'])}
                        >
                          CLOSE
                        </button>
                      </div>
                    ]}
                   >
                    <div className="container-fluid emrfrm">
                        <div className="row">
                            <div className="col-12">
                              <div className="table-responsive">
                                <table className="table table-bordered" id="obstetric-table">
                                  <thead>
                                    <tr>
                                      <th className="text-center" style={{ width: "100px" }}>YEAR</th>
                                      <th className="text-center" style={{ width: "150px" }}>MODE OF CONCEPTION</th>
                                      <th className="text-center">PREGNANCY TYPE</th>
                                      <th className="text-center">DELIVERY TYPE</th>
                                      <th className="text-center">OUTCOME</th>
                                      <th className="text-center" style={{ width: "250px" }}>REMARKS</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.obsHistoryList.map((data, i) => (
                                      <tr key={i}>
                                        <td className="text-center">{data.pregnancy_year}</td>
                                        <td className="text-center">{data.mode_text}</td>
                                        <td className="text-center">{data.pregnancy_type_text}</td>
                                        <td className="text-center">{data.delivery_type_text}</td>
                                        <td className="text-center">{data.outcome_text}</td>
                                        <td className="text-center">{data.remarks || '-'}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                        </div>
                    </div>
                </Modal>  
            </>

        )
    }
}