import React, { Component, Fragment } from 'react';
import { Select, Modal, Form, Button, Radio, Tooltip, Dropdown, Input, notification, Checkbox, AutoComplete, message } from 'antd';
import { MEDICINES_GETALL, SEARCH_DRUGS, DRUGS_CONSTANT, DRUG, PRESCRIPTION_STORE, DRUG_FAVOURITE, CIMS_INFO, CIMS_INTERACTION_EXISTS, CIMS_INTERACTION, REFERENCE_TYPE, PRESCRIPTION_DATA, DELETE_MEDICINE, MYHC_APPTYPE, WOODLANDS, HOSPITALDEFAULTS, LOADER_BLACK, LOADER, HOMECARE_CALL_RECORD_RATING, SOAP_DETAILS } from '../../utils/constant';
import 'antd/dist/antd.css';
import LS_SERVICE from '../../utils/localStorage';
import axios from 'axios';
import qs from 'qs';
import debounce from 'lodash/debounce';
import moment, { duration } from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './medicine.scss';
import calendarDatePickerIco from "../../assets/images/ic-calendar.png";
import { jQueryLabels, AntSelectLabels, AntClearLabels, FixedCta, ClearAntList, ClearSelectSelectionItem } from '../../utils/jQueryLabels';
import MedicineGridHelper from '../../utils/MedicineGridHelper';
import { medicineDataEmptyDisableButton } from '../../utils/rxjs-sharing';




const { Option } = Select;
class MedicineTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewType: 'full', //template, full
      constants: [],
      drugsData: [],
      eventBased: false,
      cimsallow: typeof LS_SERVICE.get('cimsEnabled') != 'undefined' && LS_SERVICE.get('cimsEnabled') === 'Y' ? true : false,
      patientObsGynaeInfo: {},
      patientInfo: {},
      alreadyPrescribedDrugs: [],
      //constants
      drugForm: {},
      drugDosage: {},
      drugRoute: {},
      drugDaysInWeek: {},
      drugDailyFrequency: {},
      drugDurationFrequency: {},
      drugSchedules: {},
      drugUrgency: {},
      drugInstructions: {},
      deleteMedicineModal: false,
      editMedicineDurationModal: false,
      drug_duration_time: "Days",
      number_of_days: null,
      deleteAllMedicineOnce: this.props.deleteAllMedicineOnce ? this.props.deleteAllMedicineOnce : false,
      isMedicineGrid: false,
      allMedcineList: {},
      availableDrugRoutes: [],
      drugOptions: {},
      grid_med: {},
      editable_grid: null,
      drug_daily_frequency_repeats: null,
      saveMedicineData: false,
      inputNumberType: '',
      medicineIndex: 0,
      isModalInfoVisible:false,
      drug_id:0,
      store_id:LS_SERVICE.get("STORE_ID"),
      facility_id:LS_SERVICE.get("FACILITY_ID"),
      enterprise_id:LS_SERVICE.get("ENTERPRISE_ID"),
      isEditGrid: this.props.editGrid || undefined,
      isEventBasedSS : this.props.isEventBasedSS || false,
      gynaeData: this.props.gynaeData || null,
      patientData: this.props.patientData || null
    }
    this.deleteAllmedicines = this.deleteAllmedicines.bind(this);
    this.editAllMedicinesDuaration = this.editAllMedicinesDuaration.bind(this);
    this.onChangeDays = this.onChangeDays.bind(this);
    this.handleModalPopupDuration = this.handleModalPopupDuration.bind(this);
    this.handleModalPopup = this.handleModalPopup.bind(this);
    this.handleDeletePopSubmit = this.handleDeletePopSubmit.bind(this);
    //this.handleOnChange = this.handleOnChange.bind(this);
    this.getRouteDropdown = this.getRouteDropdown.bind(this);
    this.getDosageLoadDropdown = this.getDosageLoadDropdown.bind(this);
    // this.onBlurDrugDosage = debounce(this.onBlurDrugDosage, 900);
  }

  handleInfoOk = () => { this.setState({ isModalInfoVisible: false }); };

  handleInfoCancel = (e) => { this.setState({ isModalInfoVisible: false }) };

  optionFormDrugLoad() {
    if (!this.state.constants.drugForm || this.state.constants.drugForm.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.constants.drugForm.map((obj, i) => {
      this.state.drugForm[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
      )
    }, this)
  }

  optionDosageLoad() {

    // return this.state.constants.drugDosage.map((obj, i) => {
    //   this.state.drugDosage[obj.id] = obj.description;
    // }, this)
    if (this.state.constants.drugDosage && this.state.constants.drugDosage.length > 0) {
      return this.state.constants.drugDosage.map((obj, i) => {
        this.state.drugDosage[obj.id] = obj.description;
      }, this)
    } else {
      return null;
    }

  }

  optionRouteLoad() {
    if (this.state.drugRoute && this.state.drugRoute.length > 0) {
      return this.state.constants.route.map((obj, i) => {
        this.state.drugRoute[obj.id] = obj.description;
      }, this)
    } else {
      return null;
    }

  }

  optionDaysLoad() {
    return this.state.constants.daysInWeek.map((obj, i) => {
      this.state.drugDaysInWeek[obj.id] = obj.description;
    }, this)
  }

  async saveIndex(e,i){
    await this.setState({
      medicineIndex: i
    })
  }

  optionDailyFrequencyLoad() {
    return this.state.constants.dailyFrequency.map((obj, i) => {
      this.state.drugDailyFrequency[obj.id] = obj.description;
    }, this)
  }

  optionDurationFrequencyLoad() {
    return this.state.constants.dailyFrequency.map((obj, i) => {
      this.state.drugDurationFrequency[obj.id] = obj.interval_per;
    }, this)
  }

  optionSchedulesLoad() {
    this.schedulesName = [];
    return this.state.constants.drugSchedules.map((obj, i) => {
      this.state.drugSchedules[obj.id] = obj.description;
      this.schedulesName.push(obj.description)
    }, this)
  }

  optionPriorityLoad() {
    return this.state.constants.drugUrgency.map((obj, i) => {
      this.state.drugUrgency[obj.id] = obj.description;
    }, this)
  }

  optionInstructionLoad() {
    return this.state.constants.drugInstructions.map((obj, i) => {
      this.state.drugInstructions[obj.id] = obj.description;
    }, this)
  }


  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
  }

  componentDidUpdate(prevProps, prevState) {
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    let medicineData   = nextProps.drugsData;
    let addedDrugsList = [];
    if(medicineData && medicineData.length > 0){
      addedDrugsList = medicineData.map((v, k) => { return v.drug_id });
    }else{
      addedDrugsList = 0
    }
    LS_SERVICE.set("medicnedata",addedDrugsList)
    return {
      drugsData: nextProps.drugsData,
      viewType: nextProps.viewType,
      eventBased: nextProps.eventBased,
      patientObsGynaeInfo: nextProps.patientObsGynaeInfo,
      patientInfo: nextProps.patientInfo,
      alreadyPrescribedDrugs: nextProps.alreadyPrescribedDrugs,
      constants: nextProps.constants,
      isEventBasedSS: nextProps.isEventBasedSS,
      gynaeData: nextProps.gynaeData,
      patientData: nextProps.patientData
    }
  }

  handleEditDelete = async (e, modal, data, action, idx) => {

    if (this.props.isPracticing === false) {
      this.props.practiceModal('isPracticingModal');
      return;
    }
    let showtapper = await this.checkDataIsTapper(idx);
    this.props.handlePopUp(e, modal, data, action, idx, showtapper);
  }

  handleViewInfo = async (e,drugId) => {
    this.setState({
      drug_id: drugId,
      isModalInfoVisible:true
    })
  }

  

  updateStartDate = (start_data, this_drug_id, type=null) => {
    var medicine_data = []
    start_data = moment(start_data).format('YYYY-MM-DD');
    for (var drugs of this.state.drugsData) {
      if (drugs.drug_id == this_drug_id) {
        medicine_data.push(drugs)
      }
      else {
        if(this.state.viewType != "soap-view"){
          medicine_data.push(drugs)
        }
      }
    }
    let params = {
      medicine_data,
      start_data,
      this_drug_id
    }
    if(this.state.viewType == "soap-view"){
      this.updateMedicineDate(params)
    }else{
      this.props.updateData(params)
    }
  }

  deleteAllmedicines() {
    this.setState({
      deleteMedicineModal: true
    })
  }

  editAllMedicinesDuaration() {
    this.setState({
      editMedicineDurationModal: true
    })
  }

  handleModalPopup() {
    this.setState({
      deleteMedicineModal: false
    })
  }

  handleModalPopupDuration() {
    this.setState({
      editMedicineDurationModal: false
    })
  }

  handleDeletePopSubmit() {
    let all_prescription_id = []
    this.state.drugsData.map(obj => {
      all_prescription_id.push(obj.prescription_drug_id)
    })
    this.props.handleDeleteAll(all_prescription_id);
    this.setState({
      deleteMedicineModal: false
    })
  }

  handleOnChange = async (value, optionInfo) => {

    const { allMedcineList } = this.state;
    let medData = allMedcineList;
    let addMedsTrue = allMedcineList.filter((med) => med.prescription_drug_id == optionInfo.medData.prescription_drug_id);
    let drug_id = optionInfo.medData.drug_id;
    // this.setState({ grid_med: optionInfo.medData });
    if (addMedsTrue.length == 0) {
      medData.push(optionInfo.medData);
      await this.setState({
        allMedcineList: medData
      })

      await axios.post(DRUG, qs.stringify({
        id: drug_id
      })).then(success => {
        const response = success.data.data.result;
        let availableDrugRoutesTemp = response.drug_route_for_form;
        let availableDrugRoutes = [];
        if (availableDrugRoutesTemp && availableDrugRoutesTemp.length == 0 || availableDrugRoutesTemp == null) {
          availableDrugRoutes = this.state.constants.route;
        }
        else {
          availableDrugRoutesTemp.map((v, k) => {
            availableDrugRoutes.push({ id: parseInt(v.routeId), description: v.long })
          })
        }
        // this.setState({ availableDrugRoutes: availableDrugRoutes });
        // let availableDrugRoutes = this.state.availableDrugRoutes;
        var routeForForms = this.state.constants.drugRouteForForm.filter((item, j) => item.formId == response.form_id?.id) || [];
        let allDrugRoute = this.state.constants.route;
        if (availableDrugRoutes.length == 0 || availableDrugRoutes == null) {
          availableDrugRoutes.length = []
          for (var rff of routeForForms) {
            availableDrugRoutes.push({
              id: rff.routeId, description: rff.route_descriptions
            })
          }
        }
        let drugOptions = availableDrugRoutes.length == 0 || availableDrugRoutes == null ? allDrugRoute : availableDrugRoutes
        this.setState({
          drugOptions
        })
      }).catch(err => console.log(err))
    }
  }

  async checkDataIsTapper(index) {
    let data_is = this.state.drugsData;
    let index_is = parseInt(index);
    let index_length = parseInt(data_is?.length);

    let array_data = [];
    let array_ids = [];

    if (data_is?.length > 0) {
      for (let i = 0; i < data_is.length; i++) {
        if (array_ids.includes(parseInt(i)) == false) {
          let id1 = data_is[i].drug_id;
          array_data.push(data_is[i]);

          for (let j = i + 1; j < data_is.length; j++) {
            let id2 = data_is[j].drug_id;
            if (id1 == id2) {
              array_data.push(data_is[j]);
              array_ids.push(parseInt(j));
            }
          }
        }
      }
    }

    let finaldata = array_data;
    // let finaldata = [...data_is]?.sort((a, b) => a.drug_id - b.drug_id);
    let on_samll = false;
    let on_big = false;
    let show_taper = false;
    if (index_is != 0) {
      if (finaldata[index_is - 1].drug_id == finaldata[index_is].drug_id) {
        on_samll = true
      }
    }
    if (index_is + 1 != index_length) {
      if (finaldata[index_is + 1].drug_id == finaldata[index_is].drug_id) {
        on_big = true
      }
    }
    if (on_samll || on_big) {
      show_taper = true;
    }
    console.log("show_taper", show_taper)
    return show_taper;
  }

  getRouteDropdown(data) {
    const { allMedcineList } = this.state;
    if (!allMedcineList?.hasOwnProperty(data.drug_id)) {
      return;
    }
    const { constants, drugOptions, grid_med } = this.state;
    let drug_id = data.drug_id;

    let availableDrugRoutesTemp = allMedcineList?.[data.drug_id].drug_route_for_form;
    let availableDrugRoutes = [];
    if (availableDrugRoutesTemp && availableDrugRoutesTemp.length == 0 || availableDrugRoutesTemp == null) {
      availableDrugRoutes = this.state.constants.route;
    }
    else {
      availableDrugRoutesTemp.map((v, k) => {
        availableDrugRoutes.push({ id: parseInt(v.routeId), description: v.long })
      })
    }
    var routeForForms = constants.drugRouteForForm.filter((item, j) => item.formId == this.state.allMedcineList?.med?.[data.drug_id].form_id?.id) || [];
    let allDrugRoute = constants.route;
    if (availableDrugRoutes.length == 0 || availableDrugRoutes == null) {
      availableDrugRoutes.length = []
      for (var rff of routeForForms) {
        availableDrugRoutes.push({
          id: rff.routeId, description: rff.route_descriptions
        })
      }
    }
    let drugOptionsRoute = availableDrugRoutes.length == 0 || availableDrugRoutes == null ? allDrugRoute : availableDrugRoutes;
    let oraldefaultValue = this.state.constants.route.filter((data1, i) => data1.id == data.route ? data1.description : null);
    if (drugOptionsRoute.length > 0) {
      return (<Select defaultOpen={true} showSearch placement="bottomLeft" className={oraldefaultValue[0]?.description != undefined || '' ? "drug-input drug-Route w-100" : "drug-input drug-Route custom-pink w-100"}
        filterOption={(input, option) => {
          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }} style={{ width: 80 }} defaultValue={oraldefaultValue[0]?.description && oraldefaultValue[0]?.description != '' ? oraldefaultValue[0]?.description : null} onChange={(value, optionInfo) => this.onChangeDrugRoute(value, optionInfo)}>
        {drugOptionsRoute.map((data1, i) => {
          return <Option medData={data} value={data1.id} title={data1.description} disabled={data1.description == oraldefaultValue[0]?.description}>{data1.description}</Option>
        })}
      </Select>
      )
    }
  }

  onChangeDrugRoute = (val, optionInfo) => {
    const { drugsData } = this.state;
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;
    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? data.route = val : null);
    this.saveMedicineData(data);
  }

  updateMedicineDate = async (data) => {
    let medicine_data = data.medicine_data;
    let start_data = data.start_data;
    let this_drug_id = data.this_drug_id;
    var updateddrug = [];
    let previousDrugID = null;
    let previousDate = null;
    for (var thisdrug of medicine_data) {
      if (thisdrug.drug_id == this_drug_id) {
        let { start_date, end_date } = this.getStartEndDate(thisdrug, (previousDrugID == thisdrug.drug_id && thisdrug.taper_row == true), previousDate, this.state.constants, start_data)
        thisdrug.start_date = start_date;
        thisdrug.end_date = end_date;
        updateddrug.push(thisdrug);
      }
      previousDrugID = thisdrug.drug_id;
      previousDate = thisdrug.start_date;
    }
    this.saveMedicineData(updateddrug);
  }

  getStartEndDate(data, isTaper, previousDate, constants, predefinedStartDate) {
    let start_date = null;
    let end_date = null;
    let day_interval = null;
    let times_correction = null;
    let dosage_per_day = null;
    let number_of_days = data.number_of_days;

    if (data.daily_frequency) {
      let frequencyObjKey = Object.keys(constants.dailyFrequency).find(key => constants.dailyFrequency[key].id === data.daily_frequency)
      frequencyObjKey = constants.dailyFrequency[parseInt(frequencyObjKey)]
      // day_interval = frequencyObjKey.interval;
      day_interval = data.number_of_days_type && data.number_of_days_type != null ? data.number_of_days_type.toLowerCase() : '';
      day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
      times_correction = frequencyObjKey?.total_times;
      dosage_per_day = frequencyObjKey?.total_repeats;
    }
    if (isTaper && previousDate)
      start_date = moment(previousDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    else {
      if (predefinedStartDate && predefinedStartDate != null && predefinedStartDate != undefined) {
        start_date = predefinedStartDate
      }
      else {
        start_date = moment().format('YYYY-MM-DD');
      }
    }
    //if (day_interval != 1)
    //   number_of_days = Math.round(number_of_days / dosage_per_day);
    // var endDatedays = (number_of_days * day_interval * times_correction) - 1;
    var endDatedays = (number_of_days * day_interval);
    end_date = moment(start_date, 'YYYY-MM-DD').add(endDatedays, 'days').format('YYYY-MM-DD');

    if (data.drug_frequency == 3 || times_correction == 0) {
      start_date = null;
      end_date = null;
    }
    return { start_date: start_date, end_date: end_date }

  }

  saveMedicineData = async (data, type = null) => {
    
    if (data?.length == 0) {
      return;
    }
    data[0].selected = true;
    delete data[0].dosage_rule;
    data[0]["dose_for_schedule_applicable"] = data[0]["dose_for_schedule"] || 'N';
    data[0]["correction_dose_applicable"] = data[0]["correction_dose"] || 'N';
    data[0]["i"] = this.state.medicineIndex;
    data[0]["factor1"] = this.state?.drugOptions?.factor1;
    data[0]["factor2"] = this.state?.drugOptions?.factor2;
    let data_obj = data[0];
    let medicine_count = await this.calculateQuantity(data_obj);
    data[0]["quantity"]= medicine_count.totalqty;
    data[0]["total_strips"]= medicine_count.total_strips;

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: LS_SERVICE.get('call_patient_qms_token_id'),
      patient_id: LS_SERVICE.get('call_patient_id'),
      medicine_data: data,
    };

    // console.log(data);
    // return false;

    await axios.post(PRESCRIPTION_STORE, qs.stringify(PARAMS))
      .then(success => {
        notification.success({
          message: ' Medicine data updated successfully',
          placement: 'topRight'
        });
        if (type == null) {
          this.setState({ editable_grid: null })
          this.setState({ inputNumberType: '' })
        }
        // if(LS_SERVICE.get('soap_type')=='single'){
        this.props.updateMedicineData();
        // }
      }).catch(err => {
        this.setState({ inputNumberType: '' })
        notification.error({
          message: 'Failed to update medicine data',
          placement: 'topRight'
        });
        console.log(err);
      })
  }

  saveMedicineDataInGrid = async (data, type = null) => {
    if (data?.length === 0) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      const medicine = data[i];
      medicine.selected = true;
      delete medicine.dosage_rule;
      medicine.dose_for_schedule_applicable = medicine.dose_for_schedule || 'N';
      medicine.correction_dose_applicable = medicine.correction_dose || 'N';
      medicine.i = this.state.medicineIndex;

      let drugOptions = await MedicineGridHelper.drugInfo(medicine);
      medicine.factor1 = drugOptions.factor1;
      medicine.factor2 = drugOptions.factor2;

      let medicine_count = await this.calculateQuantity(medicine, "durationChange");
      medicine.quantity = medicine_count.totalqty;
      medicine.total_strips = medicine_count.total_strips;
    }

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: LS_SERVICE.get('call_patient_qms_token_id'),
      patient_id: LS_SERVICE.get('call_patient_id'),
      medicine_data: data,
    };

    try {
      await axios.post(PRESCRIPTION_STORE, qs.stringify(PARAMS));
      notification.success({
        message: 'Medicine data updated successfully',
        placement: 'topRight'
      });
      if (type === null) {
        this.setState({ editable_grid: null });
        this.setState({ inputNumberType: '' });
      }
      this.props.updateMedicineData();
    } catch (err) {
      this.setState({ inputNumberType: '' });
      notification.error({
        message: 'Failed to update medicine data',
        placement: 'topRight'
      });
      console.log(err);
    }
  }
  
  calculateQuantity = async (data, type) => {
    let final_data_is = {}
    let drug_fixed_qty, drug_number_of_days, drug_dosage, drug_frequency, drug_daily_frequency, drug_dosage_form_text_quantity;
    drug_dosage_form_text_quantity = data.dosage_form_text;
    drug_number_of_days = data.number_of_days;
    drug_dosage = data.dosage_value;
    drug_frequency = data.drug_frequency;
    drug_daily_frequency = data.daily_frequency;

    if (drug_dosage && drug_frequency) {
      let quantity = '';
      if (!isNaN(parseInt(drug_fixed_qty))) quantity = parseInt(drug_fixed_qty);
      else {
        var number_of_days = drug_number_of_days;
        drug_dosage = parseFloat(drug_dosage);

        var day_interval;
        var dosage_per_day;
        var frequencyDescription;
        var frequencyNature;
        if (drug_daily_frequency != null && drug_daily_frequency != '') {
          let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === drug_daily_frequency)
          frequencyObjKey = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)];
          day_interval = frequencyObjKey.interval_per;
          dosage_per_day = frequencyObjKey.total_repeats;
          frequencyDescription = frequencyObjKey.description;
          frequencyNature = frequencyObjKey.nature;
        }

        if (frequencyNature == "Once" && drug_frequency == 1) {
          quantity = drug_dosage;
        } else {
            if (drug_frequency == 1 && day_interval!="Months") {
              if (drug_dosage_form_text_quantity != 'Unit' && number_of_days) {
                quantity = parseInt(number_of_days) * drug_dosage * dosage_per_day;
              } else {
                quantity = 1;
              }
            }
            if (drug_frequency == 2 && day_interval!="Months") {
              let days_in_week_text = data.days_in_week_text;
              var noOfdaysInWeek = typeof days_in_week_text != "undefined" && days_in_week_text != "" && days_in_week_text != null ? days_in_week_text.split("-").length : 1;
              if (drug_dosage_form_text_quantity != 'Unit' && number_of_days) {
                quantity = parseInt(number_of_days) * parseInt(noOfdaysInWeek) * drug_dosage * dosage_per_day;
              } else {
                quantity = 1;
              }
            }
            if (day_interval== "Months") {
              if (drug_dosage_form_text_quantity != 'Unit' && number_of_days) {
                quantity = parseInt(number_of_days) * drug_dosage * dosage_per_day;
              } else {
                quantity = 1;
              }
            }
        }

        if (data.number_of_days_type != "Days") {
          let day_qty = Math.ceil(quantity / number_of_days);
          if(drug_frequency == 1){
            if (data.number_of_days_type == "Weeks") {
              if(day_interval == "Days"){
                quantity = day_qty * drug_number_of_days * 7;
              }
            }
            if (data.number_of_days_type == "Months") {
              if(day_interval == "Days"){
                quantity = day_qty * drug_number_of_days * 30;
              }
              if(day_interval == "Weeks"){
                quantity = day_qty * drug_number_of_days * 4;
              }
            }
            if (data.number_of_days_type == "Years") {
              if(day_interval == "Days"){
                quantity = day_qty * drug_number_of_days * 365;
              }
              if(day_interval == "Weeks"){
                quantity = day_qty * drug_number_of_days * 52;
              }
              if(day_interval == "Months"){
                quantity = day_qty * drug_number_of_days * 12;
              }
            }
          }
          
          if(drug_frequency == 2){
            if (data.number_of_days_type == "Months") {
                quantity = day_qty * drug_number_of_days * 4;
            }
            if (data.number_of_days_type == "Years") {
                quantity = day_qty * drug_number_of_days * 52;
            }
          }
          
        }
      }

      if(drug_frequency == 3){
        quantity = 1;
      }

      // let factor1 = this.state?.drugOptions?.factor1;
      // let factor2 = this.state?.drugOptions?.factor2;
      let factor1, factor2;

      if (type === "durationChange") {
        factor1 = data?.factor1;
        factor2 = data?.factor2;
      } else {
        factor1 = this.state?.drugOptions?.factor1;
        factor2 = this.state?.drugOptions?.factor2;
      }

      var total_strips = '';
      if (factor1 && factor2) {
        var final_factor = factor2 / factor1;
        var total_strips_decimal = quantity / final_factor;
        var total_strips_floor = Math.floor(total_strips_decimal);
        var total_strips;
        if (total_strips_decimal == total_strips_floor) {
          total_strips = total_strips_floor
        }
        else {
          total_strips = total_strips_floor + 1
        }
      }

      final_data_is.totalqty = quantity;
      final_data_is.total_strips = total_strips;
      return final_data_is;
    }
  }
  
  getDailyFrequencyDropdown(data, frequecyDefaultValue) {
    let { allMedcineList, constants } = this.state;

    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }

    if (constants.dailyFrequency.length > 0) {
      return (<Select showSearch defaultOpen={true} className="w-100"
        placement="bottomLeft" filterOption={(input, option) => {
          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }} style={{ width: 100 }} defaultValue={frequecyDefaultValue} onChange={(value, optionInfo) => this.onSelectDailyFrequencyChange(value, optionInfo)}>
        {constants.dailyFrequency.map((data1, i) => {
          return <Option key={i} medData={data} value={data1.id} disabled={frequecyDefaultValue == data1.description} dataSchedule={data1?.schedule} dataid={data1} title={data1.description}>{data1.description}</Option>
        })}
      </Select>
      )
    }
  }



  onSelectDailyFrequencyChange = (val, props) => {
    const { drugsData } = this.state;

    if (props.dataid == undefined) {
      return;
    }
    var day_interval = props.dataid.interval_per;
    day_interval = day_interval == "Weeks" ? 7 : (day_interval == 'Months' ? 30 : (day_interval == 'Years' ? 365 : 1));
    // var times_correction = props.dataid.total_times;
    var dosage_per_day = props.dataid.total_repeats;
    let start_date;
    // var start_date = this.state.start_date;
    // if (!start_date && start_date != undefined) {
    //   if (this.state.editData != null && this.state.editData.start_date != null)
    //     start_date = this.state.editData.start_date
    //   else if (this.state.editData != null && this.state.editData.start_date == null && this.state.editData.previousEndDate != null && this.state.editData.taper_row)
    //     start_date = moment(this.state.editData.previousEndDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    //   else
    //     start_date = moment().format('YYYY-MM-DD');
    // }
    /* if (times_correction == 0) {
      start_date = moment().format('YYYY-MM-DD');
      // if (!this.state.isModalTaperDoseVisible)
      //   this.setState({ disableNumberOfDays: false })
      // else
      //   this.setState({ disableNumberOfDaysTaper: true })
    }
    else { */
      if (start_date == null) {
        start_date = moment().format('YYYY-MM-DD');
      }

      // if (!this.state.isModalTaperDoseVisible)
      //   this.setState({ disableNumberOfDays: false })
      // else
      //   this.setState({ disableNumberOfDaysTaper: false })
    // }
    let moedifiedPrescriptionDrugId = props.medData.prescription_drug_id;

    let data = drugsData.filter((data, i) => {
      if (data.prescription_drug_id == moedifiedPrescriptionDrugId) {
        data.start_date = start_date;
        data.schedules = props.dataSchedule.map(function (obj) { return obj.id });
        data.schedule_name = props.dataSchedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-');
        data.daily_frequency = props.dataid.id;
        data.number_of_days_type = day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months';
        return data;
      }
    });
    this.setState({
      drug_daily_frequency_repeats: day_interval != 1 ? 1 : parseInt(dosage_per_day),
    })
    // this.setState({
    //   drug_start_date: start_date,
    //   drug_schedules: props.dataSchedule.map(function (obj) { return obj.id }),
    //   drug_schedule_name: props.dataSchedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-'),
    //   drug_daily_frequency: props.dataid.id,
    //   
    //   drug_duration_time: day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months'
    // }, () => { this.updateQuantity(); this.updateEndDate(); this.checkDrugSaveBtn(); })

    // AntSelectLabels();
    this.updateEndDate(data)

  }

  updateEndDate = async (data, type = null) => {
    let drug_number_of_days, drug_daily_frequency, drug_start_date, drug_duration_time, drug_duration_time_taper;
    drug_start_date = data[0].start_date;
    var day_interval;
    drug_number_of_days = data[0].number_of_days;
    drug_daily_frequency = data[0].daily_frequency;
    day_interval = (data[0].number_of_days_type && data[0].number_of_days_type != null) ? data[0].number_of_days_type.toLowerCase() : '';


    if (drug_number_of_days && drug_daily_frequency && drug_start_date) {
      var number_of_days = parseInt(drug_number_of_days);
      var start_date = drug_start_date;
      var end_date = null;
      let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === drug_daily_frequency)
      frequencyObjKey = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)]
      day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
      // var times_correction = frequencyObjKey.total_times;
      var dosage_per_day = frequencyObjKey.total_repeats;
      if (typeof number_of_days != 'undefined' && !isNaN(number_of_days) && typeof start_date != 'undefined' && start_date != '' /* && times_correction != 0 */) {
        if (day_interval != 1)
          // number_of_days = Math.round(number_of_days / dosage_per_day);
          //  number_of_days = number_of_days * day_interval * times_correction;
          number_of_days = number_of_days * day_interval;
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
      }
      /* if (times_correction == 0) {
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
        data[0].start_date = start_date;
        data[0].end_date = end_date;
        // await this.setState({ drug_start_date: start_date, drug_end_date: end_date }, () => { this.checkDrugSaveBtn() });
      } else { */
        data[0].start_date = start_date;
        data[0].end_date = end_date;
        // await this.setState({ drug_end_date: end_date }, () => { this.checkDrugSaveBtn() });
      // }
    }

    else if (drug_start_date == null) {
      data[0].end_date = null;
    }
    this.saveMedicineData(data, type);

  }


  getDailyschedulesDropdown(data, schduledDefaultValue) {
    let { allMedcineList, constants } = this.state;

    if (data.schedules !== undefined && data.schedules !== null && data.schedules != "") {
      data.schedules = data.schedules.map(data => parseInt(data))
    }
    let drug_schedules = (data.active == 'Y' && data.schedules !== null) ? data.schedules : (data.schedules && data.schedules !== null) ? data.schedules : [];
    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }
    if (constants.drugSchedules.length > 0) {
      return (<Select allowClear placement="bottomLeft" defaultValue={drug_schedules} className="w-100" mode="multiple"
        filterOption={(input, option) => {
          return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }}
        onChange={(value, optionInfo) => this.selectSchedulesChange(value, optionInfo)}>
        {constants.drugSchedules.map((obj, i) => {
          return <Option key={i} medData={data} value={obj.id} title={obj.description}>{obj.description}</Option>
        })}
      </Select>
      )
    }
  }

  selectSchedulesChange = (val, props) => {

    this.setState({ saveMedicineData: this.state.drug_daily_frequency_repeats === val.length ? true : false })
    const { drugsData } = this.state;
    let moedifiedPrescriptionDrugId = props[0]?.medData?.prescription_drug_id;
    let data = drugsData.filter((data, i) => { return data.prescription_drug_id == moedifiedPrescriptionDrugId })
    this.checkDrugSave(data, val, props);

  }

  checkEmptyValue(val) {
    return typeof val == 'undefined' || val == null || val == '' ? true : false;
  }

  checkEmptyMultiSelectValue(val) {
    return typeof val == 'undefined' || val == null || val.length == 0 ? true : false;
  }


  checkDrugSave = async (data, val, props) => {
    let medicineData = data[0];
    let disableBtn = false;
    if (typeof medicineData != 'undefined') {
      let drugId = medicineData?.drug_id;
      let drugFrequency = medicineData?.drug_frequency;
      let drugDaysInWeek = medicineData?.days_in_week;
      let dailyFrequency = medicineData?.daily_frequency;
      let urgency = medicineData?.urgency;
      let numberOfDays = medicineData?.number_of_days;
      let medicineFormValidate = (medicineData?.active === 'N') ? false : true;
      let number_of_days_type = medicineData?.number_of_days_type;

      //In case of empty value
      if (this.checkEmptyValue(drugId)) disableBtn = true;

      if (drugFrequency != 3) {
        let day_interval = null;
        // let times_correction = null;
        let dosage_per_day = null;
        if (this.checkEmptyValue(dailyFrequency)) disableBtn = true;
        else {
          let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === dailyFrequency)
          frequencyObjKey = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)];
          day_interval = (number_of_days_type && number_of_days_type != null) ? number_of_days_type.toLowerCase() : '';
          day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
          dosage_per_day = frequencyObjKey.total_repeats
          // times_correction = frequencyObjKey.total_times;
          if (val && val.length > 0) {
            if ((dosage_per_day <= 4 && (dailyFrequency != 9 && dailyFrequency != 8)) && val && val.length != dosage_per_day)
              disableBtn = true;
            else if ((dosage_per_day <= 4 && (dailyFrequency == 9 || dailyFrequency == 8)) && val && val.length != 1)
              disableBtn = true;
            else if (dosage_per_day > 4 && val && val.length != 4)
              disableBtn = true;
          }
          if ((dailyFrequency == 9 || dailyFrequency == 11) && (day_interval < 7)) {
            disableBtn = true;
          }
          else if ((dailyFrequency == 8 || dailyFrequency == 10) && (day_interval < 30)) {
            disableBtn = true;
          }
        }
        if (drugFrequency == 2 && this.checkEmptyMultiSelectValue(drugDaysInWeek)) disableBtn = true;
        if (drugDaysInWeek?.length > 0) disableBtn = false;
        if (this.checkEmptyValue(urgency)) disableBtn = true;
      }

      await this.setState({ saveMedicineData: disableBtn })

      if ((drugFrequency == 1 || drugFrequency == 2) && !medicineFormValidate && numberOfDays != null) {
        this.setState({ saveMedicineData: medicineFormValidate })
      }

      if ((drugFrequency == 1 || drugFrequency == 2) && !medicineFormValidate && (numberOfDays == null || numberOfDays == "")) {
        this.setState({ saveMedicineData: true })
      }

      if (drugFrequency == 3 && !medicineFormValidate) {
        this.setState({ saveMedicineData: medicineFormValidate })
      }

      if (!medicineFormValidate) {
        this.setState({ saveMedicineData: false })
      }

    }
    const { drugsData, grid_med } = this.state;
    let moedifiedPrescriptionDrugId = grid_med.prescription_drug_id;
    if (this.state.saveMedicineData || val.length == 0) {
      notification.error({
        message: 'Please select specific frequency',
        placement: 'topRight'
      });

    } else {
      let data = drugsData.filter((data, i) => {
        if (data.prescription_drug_id == moedifiedPrescriptionDrugId) {
          data.schedules = val;
          data.schedule_name = props.length != 0 ? props.map(function (obj) { return obj.title }).toString().replace(/,/g, '-') : '';
          return data;
        }
      });
      this.saveMedicineData(data);
    }
  }


  checkDrugSaveDuration = async (data, val) => {
    let medicineData = data[0];
    let flag = false;
    if (typeof medicineData != 'undefined') {
      let drugFrequency = medicineData?.drug_frequency;
      let dailyFrequency = medicineData?.daily_frequency;
      if (drugFrequency != 3) {
        let day_interval = null;
        if (this.checkEmptyValue(dailyFrequency)) flag = true;
        else {
          let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === dailyFrequency)
          frequencyObjKey = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)];
          let interval_per = frequencyObjKey.interval_per;
          day_interval = interval_per == "Weeks" ? 7 : (interval_per == 'Months' ? 30 : 1);
          if (drugFrequency == 1) {
            if (day_interval == 7 && val == "Days") {
              flag = true;
            }
            if (day_interval == 30 && (val == "Days" || val == "Weeks")) {
              flag = true;
            }
          }
          if (drugFrequency == 2 && val == "Days") {
            flag = true;
          }
        }
      }
    }
    return flag;
  }

  getDosageLoadDropdownSchduled(data, sch) {
    let { allMedcineList, constants } = this.state;
    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }
    let dosageVal;
    let dosageFormText;
    if (sch == 1) {
      dosageVal = data.dosage_value_morning;
      dosageFormText = data.dosage_form_text;
    } else if (sch == 2) {
      dosageVal = data.dosage_value_afternoon;
      dosageFormText = data.dosage_form_text;
    } else if (sch == 3) {
      dosageVal = data.dosage_value_evening;
      dosageFormText = data.dosage_form_text;
    } else if (sch == 4) {
      dosageVal = data.dosage_value_night;
      dosageFormText = data.dosage_form_text;
    }
    return (<React.Fragment><><div><p><Input onBlur={(event) => this.onBlurDosageScheduled(event, data, sch)} defaultValue={dosageVal} placeholder="Dosage"/></p><p>{dosageFormText}</p></div></>
    </React.Fragment>)
  }

  onBlurDosageScheduled(event, med_data, sch) {
    let val = event.target.value;
    if (val === "" || /^[0-9]*(\.[0-9]{0,2})?$/.test(val)) {
      let dosage = val;
      const { drugsData, drugDosage } = this.state;
      let dosageSyntax = sch == 1 ? 'dosage_value_morning' : sch == 2 ? 'dosage_value_afternoon' : sch == 3 ? 'dosage_value_evening' : 'dosage_value_night';
      console.log(dosageSyntax);
      let moedifiedPrescriptionDrugId = med_data.prescription_drug_id;

      let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? (data[dosageSyntax] = parseFloat(val), data.dosage = dosage) : null);
      data[0].i = 2;
      data[0].dose_for_schedule_applicable = 'Y';
      this.saveMedicineData(data);
    }
  }


  handleDosageSchduled(val, optionInfo, sch) {
    const { drugsData, drugDosage } = this.state;
    let dosageSyntax = sch == 1 ? 'dosage_value_morning' : sch == 2 ? 'dosage_value_afternoon' : sch == 3 ? 'dosage_value_evening' : 'dosage_value_night';
    var dosage = Object.keys(drugDosage).find(key => this.state.drugDosage[key] == val);
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;

    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? (data[dosageSyntax] = parseFloat(val), data.dosage = dosage) : null);
    data[0].i = 2;
    data[0].dose_for_schedule_applicable = 'Y';
    this.saveMedicineData(data);
  }


  getDosageLoadDropdown(data) {
    let { allMedcineList, constants } = this.state;
    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }

    let dosageVal;
    let dosageFormText;
    if (data.dosage_value != undefined) {
      dosageVal = data.dosage_value;
      dosageFormText = data.dosage_form_text;
    } else {
      dosageFormText = data.dosage_form_text;
    }
    console.log("this.state.constants.drugDosage dosageVal", dosageVal, typeof dosageVal);
    return (<React.Fragment><><div><p>
      <Tooltip placement="topLeft" title={dosageVal == undefined ? "Dosage is mandatory" : null}> 
      {/* <AutoComplete defaultOpen
        onBlur={(event) => this.onBlurDrugDosage(event, data)} filterOption={(inputValue, option) => typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1}
        showSearch placement="bottomLeft" placeholder="Dosage" className={dosageVal == undefined || '' ? "drug-input drug-Dosage custom-pink" : "drug-input drug-Dosage"}
        style={{ width: 80 }} defaultValue={dosageVal} onSelect={(value, optionInfo) => this.handleOnDosageValue(value, optionInfo)}>
        {this.state.constants.drugDosage.map((obj, i) => {
          // this.state.drugDosage[obj.id] = "" + obj.description;
          return <Option key={i} medData={data} value={obj.description} disabled={dosageVal == obj.description} title={obj.description}>{obj.description}</Option>
        })}
      </AutoComplete> */}
      <Input defaultOpen onBlur={(event) => this.onBlurDrugDosage(event, data)} placeholder="Dosage" className={dosageVal == undefined || '' ? "drug-input drug-Dosage custom-pink" : "drug-input drug-Dosage"} defaultValue={dosageVal}  type="text" maxLength="10"/>
      </Tooltip>
    </p><p>{dosageFormText}</p></div></>
    </React.Fragment>)
  }

  onBlurDrugDosage = (event, data) => {
    var val = event.target.value;
    if (val === "" || /^[0-9]*(\.[0-9]{0,2})?$/.test(val)) {
      var dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == val);
      var DRUGSConstants = this.state.constants;
      var drugDosage = this.state.drugDosage;
      var highestDosageId = Math.max(...Object.keys(drugDosage).map(Number))

      var drugDosageValue = null;
      if (typeof dosage == 'undefined' && val != null && val != '') {
        this.state.constants.drugDosage.map((obj, i) => {
          highestDosageId = highestDosageId == null || highestDosageId < obj.id ? obj.id : highestDosageId;
        })
        drugDosage[parseInt(highestDosageId) + 1] = "" + val;
        DRUGSConstants.drugDosage.push({ id: parseInt(highestDosageId) + 1, description: val });
        drugDosageValue = parseInt(highestDosageId) + 1;
        this.setState({ drugDosage, DRUGSConstants });
      }
      else {
        drugDosageValue = dosage
      }
      let optionInfo = {};
      optionInfo.medData = data;
      this.handleOnDosageValue(val, optionInfo)

    } else {
      notification.error({
        message: 'Please type specific dosage'
      })
    }
    // return drugDosageValue;
  }


  // onSearchDrugDosage = (val) => {
  //   if (val === "" || /^[1-9]\d*(?:\.\d{0,2})?$/.test(val)) {
  //     this.setState({ dosageDropDown: val })
  //   }

  // }

  handleOnDosageValue = (val, optionInfo) => {
    const { drugsData, drugDosage } = this.state;
    if (val === "" || /^[0-9]*(\.[0-9]{0,2})?$/.test(val)) {

      var dosage = Object.keys(drugDosage).find(key => this.state.drugDosage[key] == val);
      let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;

      let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? (data.dosage_value = val, data.dosage = val) : null);

      this.saveMedicineData(data);
    }

  }

  getInstructionDropdownSchduled(data, sch) {
    let { allMedcineList, constants } = this.state;

    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return
    }
    let Instruction;
    let InstructionOptionVal;
    if (sch == 1) {
      Instruction = constants.drugMorningInstructions.filter((data1, i) => data1.id == data.instruction_morning ? data1.description : null);
      InstructionOptionVal = constants.drugMorningInstructions;
    } else if (sch == 2) {
      Instruction = constants.drugAfternoonInstructions.filter((data1, i) => data1.id == data.instruction_afternoon ? data1.description : null);
      InstructionOptionVal = constants.drugAfternoonInstructions;
    } else if (sch == 3) {
      Instruction = constants.drugEveningInstructions.filter((data1, i) => data1.id == data.instruction_evening ? data1.description : null);
      InstructionOptionVal = constants.drugEveningInstructions;
    } else if (sch == 4) {
      Instruction = constants.drugNightInstructions.filter((data1, i) => data1.id == data.instruction_night ? data1.description : null);
      InstructionOptionVal = constants.drugNightInstructions;
    } else {
      Instruction = null;
      InstructionOptionVal = null;
    }
    if (constants.drugSchedules.length > 0) {
      return (<Select showSearch={false} defaultOpen={true} className="w-100"
        placement="bottomLeft" 
        defaultValue={Instruction[0]?.description} onChange={(value, optionInfo) => this.changeInstrutionSchduled(value, optionInfo, sch)}>
        {InstructionOptionVal.map((obj, i) => {
          return <Option key={i} medData={data} disabled={Instruction[0]?.description == obj.description} value={obj.id} title={obj.description}>{obj.description}</Option>
        })}
      </Select>
      )
    }
  }

  changeInstrutionSchduled(val, optionInfo, sch) {
    const { drugsData } = this.state;
    let instructionSyntax = sch == 1 ? 'instruction_morning' : sch == 2 ? 'instruction_afternoon' : sch == 3 ? 'instruction_evening' : 'instruction_night';
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;
    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? data[instructionSyntax] = val : null);
    // data[0].i = 2;
    data[0].dose_for_schedule_applicable = 'Y';
    this.saveMedicineData(data);

  }

  getInstructionDropdown(data) {
    let { allMedcineList, constants } = this.state;

    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }

    let instructionDefault = constants.drugInstructions.filter((data1, i) => data1.id == data.instruction ? data1.description : null)
    if (constants.drugSchedules.length > 0) {
      return (<Select defaultOpen={true} showSearch={false} placement="bottomLeft" className='w-100'
        defaultValue={instructionDefault[0]?.description} onChange={(value, optionInfo) => this.handleOnChangeInstruction(value, optionInfo)}>
        {constants.drugInstructions.map((obj, i) => {
          return <Option key={i} medData={data} disabled={instructionDefault[0]?.description == obj.description} value={obj.id} title={obj.description}>{obj.description}</Option>
        })}
      </Select>
      )
    }
  }


  handleOnChangeInstruction = (val, optionInfo) => {
    const { drugsData } = this.state;
    let moedifiedPrescriptionDrugId = optionInfo.medData.prescription_drug_id;
    let data = drugsData.filter((data, i) => data.prescription_drug_id == moedifiedPrescriptionDrugId ? data.instruction = val.toString() : null);
    this.saveMedicineData(data);

  }

  getDurationDropdown(data, durationDefaultValue) {

    let { allMedcineList, constants } = this.state;

    if (!allMedcineList.hasOwnProperty(data.drug_id)) {
      return;
    }
    let numberofDays = data.number_of_days;
    let NumberOfDaysType = durationDefaultValue;
    return (<React.Fragment><><div><p> <Input onBlur={(event) => this.onBlurNumberOfDays(event, data)} defaultValue={numberofDays} maxLength={3} placeholder="No of Days" rules={[{ type: 'number' }]} onChange={(e, data) => { this.onChangeNumberOfDays(e, data) }} /> </p>
      <p>
        <Select defaultValue={NumberOfDaysType} id="drug_duration_time" name="drug_duration_time" placeholder="Duration" onSelect={(value, optionInfo) => this.selectDurationChange(value, optionInfo, numberofDays)}
        // className="input-group-addons med_addon_height"
        // value={this.state.drug_duration_time} 
        >
          {this.optionDurationTypeLoad(data)}
        </Select>
      </p>
    </div></></React.Fragment>)
  }

  onBlurNumberOfDays(event, data) {
    let val = event.target.val;
    const { inputNumberType } = this.state
    if (inputNumberType == '' && val == '') {
      notification.error({
        message: "Please type correct number of days"
      })
      return;
    }
    data.number_of_days = inputNumberType == '' ? val : inputNumberType;
    data.addContinueTillReview='N';
    let MedicineData = [];
    MedicineData.push(data)
    this.updateEndDate(MedicineData, 'numberofDay')

    // this.saveMedicineData(MedicineData, 'numberofDay');
  }

  optionDurationTypeLoad(data) {
    return (
      <>
        <Option medData={data} key={1} value="Days" title="Days">Days</Option>
        <Option medData={data} key={2} value="Weeks" title="Weeks">Weeks</Option>
        <Option medData={data} key={3} value="Months" title="Months">Months</Option>
        <Option medData={data} key={4} value="Years" title="Years">Years</Option>
      </>
    )
  }


  onChangeNumberOfDays = (val, data) => {
    if (/^0/.test(val.target.value)) {
      this.setState({ inputNumberType: '' })
      return;
    } else {
      this.setState({ inputNumberType: !isNaN(val.target.value) ? val.target.value : '' })


      //  , () => {
      //     this.updateEndDate();
      //     this.updateQuantity();
      //     this.checkDrugSaveBtn();
      //   });

      //   if (val.target.value === "") {
      //     this.setState({ drug_end_date: null })
      //   }
    }
  }

  async selectDurationChange(value, props, numberofDays) {
    const { drugsData, inputNumberType } = this.state;
    let moedifiedPrescriptionDrugId = props.medData?.prescription_drug_id;
    let data = drugsData.filter((data, i) => { return data.prescription_drug_id == moedifiedPrescriptionDrugId })
    var drugDuration;
    data[0].number_of_days = inputNumberType != '' && inputNumberType != undefined ? inputNumberType : numberofDays;
    data[0]["addContinueTillReview"] = "N";
    await this.checkDrugSaveDuration(data, value).then((response) => {
      drugDuration = response;
    })
    if (drugDuration == true) {
      notification.error({
        message: 'Please select specific duration '
      })
      return
    }
    data[0].number_of_days_type = value;
    this.updateEndDate(data)

  }

  onClickMedData = async (data, i, e, grid, sch) => {
    if (this.props.editGrid == undefined || data.medicineType == 'N') {
      return;
    }

    let edittable = ['schedule', 'frequency', 'duration'];
    if (data.drug_frequency == 3 && edittable.includes(grid)) {
      // notification.error({
      //   message: 'You cannot edit medicine having frequecy type prn',
      //   placement: 'topRight'
      // });
      return
    }
    let edittableCaseofSchdule = ['schedule', 'frequency']
    if (data.drug_frequency == 2 && edittableCaseofSchdule.includes(grid)) {
      // notification.error({
      //   message: 'You cannot change frequency you have to edit medicine',
      //   placement: 'topRight'
      // });
      return
    }

    let { allMedcineList, editable_grid } = this.state;
    if (typeof sch != 'undefined') {
      await this.setState({ editable_grid: grid + '_' + i + '_' + sch })
    } else {
      await this.setState({ editable_grid: grid + '_' + i })
    }

    let grid_med = data;
    this.setState({ grid_med: grid_med });
    if (typeof allMedcineList[data.drug_id] != 'undefined') {
      return;
    }

    await MedicineGridHelper.drugInfo(data).then(response => {
      allMedcineList[data.drug_id] = response;
      this.setState({
        drugOptions: response,
        allMedcineList: allMedcineList
      });
    }).catch(err => console.log(err));

  }

  checkMedicineIsEdidtable(data, grid_type) {
    let classNameAdded = false;
    if (data.drug_frequency == 2 || data.drug_frequency == 3) {
      classNameAdded = true
    }
    return classNameAdded;
  }

  onChangeDurationType = async (val) => {
    await this.setState({ drug_duration_time: val, })
  }

  onChangeDays = (event) => {
    this.setState({ number_of_days: event.target.value });
  }

 
  saveAllMedicineData = async () => {
    const { drugsData, number_of_days, drug_duration_time, number_of_days_type } = this.state;

    const updatedMedicineData = drugsData.map(data => {
      return {
        ...data,
        number_of_days: number_of_days,
        number_of_days_type: data.taper_row == true ? data.number_of_days_type : (data.daily_frequency === 9 || data.daily_frequency === 11 || data.daily_frequency === 14) ? (drug_duration_time === 'Days' ? 'Weeks' : drug_duration_time) : (data.daily_frequency === 8 || data.daily_frequency === 10) ? (drug_duration_time === 'Days' || drug_duration_time === 'Weeks' ? 'Months' : drug_duration_time) : drug_duration_time,
      };
    });

    await this.saveMedicineDataInGrid(updatedMedicineData);

    this.setState({
      editAllMedicinesDurationModal: false,
      number_of_days: number_of_days,
      drug_duration_time: number_of_days_type
    });

    this.handleModalPopupDuration();
  }

  render() {
    let { editable_grid, isEditGrid } = this.state;
    this.optionFormDrugLoad();
    this.optionDosageLoad();
    this.optionRouteLoad();
    this.optionDaysLoad();
    this.optionDailyFrequencyLoad();
    this.optionDurationFrequencyLoad();
    this.optionSchedulesLoad();
    this.optionPriorityLoad();
    this.optionInstructionLoad();
    let medicineRows = {};
    if (this.state.drugsData && this.state.drugsData.length > 0) {
      for (const medInfo of this.state.drugsData) {
        let medKey = medInfo['drug_id'];
        if (medInfo['dose_for_schedule'] == 'Y' && medInfo['schedules']?.length > 1) {
          medicineRows[medKey] = medicineRows[medKey] !== undefined ? (medicineRows[medKey] + medInfo['schedules'].length) : medInfo['schedules'].length;
        }
        else {
          medicineRows[medKey] = medicineRows[medKey] !== undefined ? (medicineRows[medKey] + 1) : 1;
        }
        if (medInfo['correction_dose'] == 'Y' && medInfo['correction_dose_details']?.length <= 0) {
          var final_cd = []
          for (var cd of medInfo['correction_dose_data']) {
            final_cd.push([cd.range_min, cd.range_max, cd.range_unit])
          }
          medInfo['correction_dose_details'] = final_cd
        }
      }
    }

    let iteratedDrugs = [];
    let mederowCount = 1;
    let medicine_remarks = '';
    let correction_dose = 'N';
    let correction_dose_data;
    let final_medicines_data = [];
    let medi_table = this.state.drugsData;

    let array_data = [];
    let array_ids = [];
    if (medi_table?.length > 0) {
      for (let i = 0; i < medi_table.length; i++) {
        if (array_ids.includes(parseInt(i)) == false) {
          let id1 = medi_table[i].drug_id;
          array_data.push(medi_table[i]);

          for (let j = i + 1; j < medi_table.length; j++) {
            let id2 = medi_table[j].drug_id;
            if (id1 == id2) {
              array_data.push(medi_table[j]);
              array_ids.push(parseInt(j));
            }
          }
        }
      }
    }
    final_medicines_data = array_data;
    // final_medicines_data = [...medi_table]?.sort((a, b) => a.drug_id - b.drug_id);
    var count_med = 1;

    return (
      <Fragment>
        {this.state.drugsData && this.state.drugsData.length > 0 ?
          <>
            <div className="table-responsive emrfrm">
              <table className="table table-bordered custom-plan-header mb-0 mt-1">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th width="18%">MEDICINE NAME</th>
                    {/* {this.state.viewType != 'copy-from-prescription' ? (
                        <th>STRENGTH</th>
                    ) : null} */}
                    <th width="8%">ROUTE</th>
                    <th width="12%">FREQUENCY</th>
                    <th width="14%">SCHEDULE</th>
                    <th width="8%">DOSAGE</th>
                    <th width="12%">INSTRUCTIONS</th>
                    {/* <th width={`${this.state.viewType == "soap-view" ? "7%": this.state.viewType == "copy-to-visit" ? "5%" : "7%"}`}>Duration</th> */}
                    <th width="80">
                      <div className="d-flex align-items-center">
                        <span className='pr-2'>Duration</span>
                        {this.state.viewType === "soap-view" && (
                          <span>
                            <i className='icon-edit cursor-p' onClick={this.editAllMedicinesDuaration}></i>
                          </span>
                        )}
                      </div>
                    </th>
                    {(((this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.state.eventBased == true) || (this.state.viewType != 'template' && this.state.viewType != 'list-visit')) ? <th width={`${this.state.viewType == "soap-view" ? "10%": this.state.viewType == "copy-to-visit" ? "50%" : "10%"}`}>DUE FROM</th> : null}
                    {this.props.showAction ? <th width="80"><div className="d-flex align-items-center"><span className='pr-2'>Action</span>  {this.state.deleteAllMedicineOnce == true ? <span><Tooltip placement="topLeft" title="Delete All"><i onClick={this.deleteAllmedicines} className='icon_delete icon_delete_all cursor-pointer mt-1'></i></Tooltip></span> : null}</div>
                    </th> : null}
                  </tr>
                </thead>
                <tbody>
                  {final_medicines_data.map((data, i) => {
                    let classNameAdded = this.checkMedicineIsEdidtable(data);
                    var deleteIndex = this.state.viewType == 'visit-full' ? data.id : i;
                    var drugId = data.drug_id;
                    let medexists;
                    var protocol_start_date = moment(data.start_date).format('DD MMM, YYYY');
                    let schduledDefaultValue = data.schedule_name != null && data.schedule_name ? data.schedule_name : '-';
                    let showBackgroundRoute = data.route != null ? this.state.constants.route.find((data1, i) => data1.id == data.route ? data1.description : null) : null;
                    let dosageBackground = data.dosage_value;

                    if(typeof data.start_date_protocol == 'undefined'){
                      if (data.event && data.event > 0) {
                        var predefined_start_date;
                        if (data.event == 1 || data.event == 2) {
                          var days = 0;
                          if (data?.lmp_event_duration_type == 'Months') {
                            days = data?.lmp_event_duration * 30
                          }
                          else if (data?.lmp_event_duration_type == 'Weeks') {
                            days = data?.lmp_event_duration * 7
                          }
                          else {
                            days = data?.lmp_event_duration
                          }
                          if (data.event == 1) {
                            if (this.state.gynaeData?.lmp && this.state.gynaeData?.lmp != null) {
                              var lmp_date = new Date(this.state.gynaeData?.lmp)
                              var predefined_start_date_unformatted = new Date(lmp_date.setDate(lmp_date.getDate() + days));
                              predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                            }
                            else {
                              predefined_start_date = moment().format('YYYY-MM-DD')
                            }
                          }
                          else {
                            if (this.state.patientData?.dob && this.state.patientData?.dob != null) {
                              var birth_date = new Date(this.state.patientData?.dob)
                              var predefined_start_date_unformatted = new Date(birth_date.setDate(birth_date.getDate() + days));
                              predefined_start_date = moment(predefined_start_date_unformatted).format('YYYY-MM-DD')
                            }
                            else {
                              predefined_start_date = moment().format('YYYY-MM-DD')
                            }
                          }
                        }
                      }
                      data.start_date_protocol = moment(predefined_start_date).format('YYYY-MM-DD');
                    }

                    if (!iteratedDrugs.includes(data.drug_id)) {
                      medexists = false;
                      mederowCount = 1;
                      medicine_remarks = data.medicine_remarks
                      correction_dose = data.correction_dose
                      correction_dose_data = data.correction_dose_data
                      iteratedDrugs.push(data.drug_id);
                    } else {
                      medexists = true;
                      if (medicine_remarks === '') {
                        medicine_remarks = data.medicine_remarks;
                        correction_dose = data.correction_dose
                        correction_dose_data = data.correction_dose_data
                      }
                    }
                    if (data.schedules === null) {
                      data.schedule_name = null
                    }
                    var custom_bgcolor;
                    var show_original_due = false;
                    if (this.state.alreadyPrescribedDrugs?.includes(data.drug_id)) {
                      custom_bgcolor = '#dddbdb'
                    }
                    else if (moment(data.start_date_protocol).diff(moment(), 'days') < 0) {
                      custom_bgcolor = '#f7eaea'
                      show_original_due = true;
                    }
                    else if (moment(data.start_date).diff(moment(), 'days') == 0) {
                      custom_bgcolor = '#dcedf4'
                    }

                    const drugRowSpan = medicineRows[data.drug_id] !== undefined && medicineRows[data.drug_id] > 1 ? medicineRows[data.drug_id] : 1;
                    let drugFreqText = data.drug_frequency ? Object.keys(this.state.constants.drugFrequency).find(key => this.state.constants.drugFrequency[key].id === data.drug_frequency) : null;
                    drugFreqText = drugFreqText != null ? this.state.constants.drugFrequency[drugFreqText].description : '';
                    let htmlData = [];
                    let frequecyDefaultValue = data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? data.days_in_week_text : (data.drug_frequency == 1 ? this.state.drugDailyFrequency[data.daily_frequency] ? this.state.drugDailyFrequency[data.daily_frequency] : '-' : drugFreqText);
                    let durationDefaultValue = data?.number_of_days_type !== null ? data?.number_of_days_type : this.state.drugDurationFrequency[data.daily_frequency];
                    var countRowspan = drugRowSpan || 1;
                    if(correction_dose == 'Y'){
                      countRowspan = countRowspan+1
                    }
                    if(medicine_remarks !== '' && medicine_remarks !== null){
                      countRowspan = countRowspan+1
                    }
                    if(i!=0 && !medexists){
                      count_med += 1
                    }
                    if (data.dose_for_schedule == 'Y' && data?.schedules?.length > 1){
                      var counter = 1;
                      data.drug_duration_time = (data.daily_frequency == 8 || data.daily_frequency == 10) ? 'Months' : (data.daily_frequency == 9 || data.daily_frequency == 11) ? 'Weeks' : 'Days';
                      data?.schedules.map((sch, schIndex) => {
                        if (counter == 1) {
                          htmlData.push(
                            <>
                              <tr key={i}>
                                {drugRowSpan > 1 && !medexists ? <td rowSpan={countRowspan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included' : 'text-dark')} style={{fontWeight:500}}>{count_med}</td> : null}
                                {drugRowSpan === 1 && !medexists ? <td rowSpan={countRowspan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included' : 'text-dark')} style={{fontWeight:500}}>{count_med}</td> : null}
                                {drugRowSpan > 1 && !medexists ? <td rowSpan={drugRowSpan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included  remark-border' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included remark-border' : 'text-dark tablet-border')} style={{ fontWeight: 500 }}>{data.drug_name}</td> : null}
                                {drugRowSpan === 1 && !medexists ? <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included  remark-border' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included remark-border' : 'text-dark tablet-border')} style={{ fontWeight: 500 }}>{data.drug_name}</td> : null}
                                {/* {this.state.viewType != 'copy-from-prescription' ? (
                                  <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.strength}</td>
                                ) : null} */}
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'route')}>{(editable_grid == 'route_' + i.toString()) ? this.getRouteDropdown(data) : <React.Fragment>
                                  <span className={this.props.editGrid == undefined ? null : `${data.medicineType=='F'? 'custom-dp' : null} ${showBackgroundRoute == null && data.medicineType=='F'? 'custom-empty-pink' : null}`}>
                                    {data.route != null ? this.state.constants.route.map((data1, i) => data1.id == data.route ? data1.description : null) : '-'}
                                  </span>
                                </React.Fragment>}
                                </td>
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} >
                                  {data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? `${this.state.drugDailyFrequency[data.daily_frequency]}  (${data.days_in_week_text})` : (data.drug_frequency == 1 ? this.state.drugDailyFrequency[data.daily_frequency] ? this.state.drugDailyFrequency[data.daily_frequency] : '-' : drugFreqText == "PRN" ? "PRN/SOS": drugFreqText)}</td>

                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  <React.Fragment> {sch == 1 ? 'Morning' : null}
                                    {sch == 2 ? 'Afternoon' : null}
                                    {sch == 3 ? 'Evening' : null}
                                    {sch == 4 ? 'Night' : null}</React.Fragment>
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'dosage', schIndex)}>{(editable_grid == 'dosage_' + i.toString() + '_' + schIndex.toString()) ? this.getDosageLoadDropdownSchduled(data, sch) : <React.Fragment>
                                  <span className={isEditGrid == undefined ? null : `${data.medicineType=='F'? 'custom-tb' : null}`}>
                                    {sch == 1 ? data.dosage_value_morning + ' ' + data.dosage_form_text : null}
                                    {sch == 2 ? data.dosage_value_afternoon + ' ' + data.dosage_form_text : null}
                                    {sch == 3 ? data.dosage_value_evening + ' ' + data.dosage_form_text : null}
                                    {sch == 4 ? data.dosage_value_night + ' ' + data.dosage_form_text : null}
                                  </span>
                                </React.Fragment>}
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'instruction', schIndex)}>
                                  {(editable_grid == 'instruction_' + i.toString() + '_' + schIndex.toString()) ? this.getInstructionDropdownSchduled(data, schIndex + 1) : <React.Fragment>
                                    <span className={isEditGrid == undefined ? null : `${data.medicineType=='F'? 'custom-dp' : null}`}>
                                      {sch == 1 ? this.state.constants.drugMorningInstructions.map((data1, i) => data1.id == data.instruction_morning ? data1.description : null) : null}
                                      {sch == 2 ? this.state.constants.drugAfternoonInstructions.map((data1, i) => data1.id == data.instruction_afternoon ? data1.description : null) : null}
                                      {sch == 3 ? this.state.constants.drugEveningInstructions.map((data1, i) => data1.id == data.instruction_evening ? data1.description : null) : null}
                                      {sch == 4 ? this.state.constants.drugNightInstructions.map((data1, i) => data1.id == data.instruction_night ? data1.description : null) : null}
                                    </span>
                                  </React.Fragment>}
                                </td>
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'duration', schIndex)}>{(editable_grid == 'duration_' + i.toString() + '_' + schIndex.toString()) ? this.getDurationDropdown(data, durationDefaultValue) : <React.Fragment>
                                <span className={isEditGrid == undefined || (classNameAdded && data.drug_frequency == 3) ? null : `${data.medicineType=='F'? 'custom-tb' : null} ${data.number_of_days ? '' : (parseInt(data?.drug_frequency) != 3 ? (parseInt(data?.daily_frequency) == 4 ? 'custom-empty-pink' : '') : 'custom-empty-pink')}`}>
                                    {data.number_of_days ? data.number_of_days + " " + (data?.number_of_days_type !== null ? data?.number_of_days_type : this.state.drugDurationFrequency[data.daily_frequency]) : (parseInt(data?.drug_frequency) != 3 ? (parseInt(data?.daily_frequency) == 4 ? '-' : 'Till review') : '-')}
                                  </span>
                                </React.Fragment>}
                                </td>

                                {(this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.state.eventBased == true ?
                                  <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.event !== null ? this.state.constants.drugEvent.map((data1, i) => data1.id === data.event ? data1.description : null) : ''} {data.event == 1 || data.event == 2 ? data.lmp_event_duration : null} {data.event == 1 || data.event == 2 ? data.lmp_event_duration_type : null}</td>
                                  : null}
                                {
                                  this.state.viewType != 'template' && this.state.viewType != 'list-visit'
                                    ?
                                    <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                      <div>
                                        {
                                          data?.event == 4
                                            ?
                                            <>
                                              {
                                                moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                                  ?
                                                  '-'
                                                  :
                                                  <>
                                                    <div className="form-group hasdata">
                                                      <DatePicker
                                                        id="protocol_start_date"
                                                        name="protocol_start_date"
                                                        data-drug={data.drug_id}
                                                        minDate={moment().toDate()}
                                                        defaultPickerValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                        defaultValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                        value={moment(protocol_start_date).format('D MMM, YYYY')}
                                                        className="form-control datetimepicker"
                                                        onChange={(e) => { this.updateStartDate(e, data.drug_id, null) }}
                                                        placement="bottomLeft"
                                                      />
                                                      <label htmlFor="key_finding_date_time">Custom Date</label>
                                                    </div>
                                                  </>
                                              }
                                            </>
                                            :
                                            <>
                                              {
                                                moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                                  ?
                                                  '-'
                                                  :
                                                  <>
                                                    {moment(data.start_date).format('D MMM, YYYY')}
                                                    <span style={{ fontSize: "12px" }}>
                                                      {
                                                        show_original_due == true
                                                          ?
                                                          <>
                                                            <br />
                                                            (Due from {moment(data.start_date_protocol).format('D MMM, YYYY')})
                                                          </>
                                                          :
                                                          null
                                                      }
                                                    </span>
                                                  </>
                                              }
                                            </>
                                        }
                                      </div>
                                      <div className={data.urgency == 1 ? "text-secondary" : null}>
                                        {data.urgency !== null ? this.state.constants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null) : ''}
                                      </div>
                                    </td>
                                    :
                                    null
                                }

                                {/* <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.start_date ? data.start_date : '-'}</td> */}
                                {this.props.showAction ?
                                  <td rowSpan={data?.schedules?.length} >
                                    {
                                      this.state.viewType != 'template' && this.state.viewType != 'soap-full' ?
                                        <a className="nostyle-link mr-2" onClick={e => this.handleEditDelete(e, 'showMedicineModal', data, 'editMedicine', i)}><i className="icon-edit"></i></a>
                                        :
                                        <a className="nostyle-link mr-2" onClick={e => this.props.handleEdit(data, i)}><i className="icon-edit"></i></a>
                                    }
                                    <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', '', 'deleteMedicine', deleteIndex)}><i className="icon_delete"></i></a>
                                    {this.state.cimsallow ? (
                                      <a className="nostyle-link ml-1" onClick={(e) => this.handleViewInfo(e, drugId)}><i className='icon_info' style={{width:'17px',height:'17px'}}></i></a>
                                    ) : null}
                                    {this.props.showSelectCheck ?
                                      <span className="select-diagnosis select-check">
                                        <i data-checkSelect={i} data-type={'drugs'} onClick={(e) => this.props.updateSelection(i, 'drugsData')}
                                          className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                                      </span>
                                      : null}
                                  </td>
                                  : null}
                              </tr>
                              {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                                <tr>
                                  <td colspan="9" className="remarks-column">
                                    <span className='bold-500'>Remarks :&nbsp;</span><span>{medicine_remarks}</span>
                                  </td>
                                </tr>
                                : null}
                              {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                                <>
                                  <tr>
                                    <td colspan="9" className="py-2">
                                      <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                      <table className="table" style={{ width: "auto" }}>
                                        <thead>
                                          <tr>
                                            <th width="200">Blood Sugar (mg/dL)</th>
                                            {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Insulin (Units)</td>
                                            {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                                : null}
                            </>
                          )
                          mederowCount = mederowCount + 1;
                        }
                        else {
                          htmlData.push(
                            <>
                              <tr key={i}>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>

                                  <React.Fragment> {sch == 1 ? 'Morning' : null}
                                    {sch == 2 ? 'Afternoon' : null}
                                    {sch == 3 ? 'Evening' : null}
                                    {sch == 4 ? 'Night' : null}
                                  </React.Fragment>

                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'dosage', schIndex)}>{(editable_grid == 'dosage_' + i.toString() + '_' + schIndex.toString()) ? this.getDosageLoadDropdownSchduled(data, sch) : <React.Fragment>
                                  <span className={isEditGrid == undefined ? null : `${data.medicineType=='F'? 'custom-tb' : null}`}>
                                    {sch == 1 ? data.dosage_value_morning + ' ' + data.dosage_form_text : null}
                                    {sch == 2 ? data.dosage_value_afternoon + ' ' + data.dosage_form_text : null}
                                    {sch == 3 ? data.dosage_value_evening + ' ' + data.dosage_form_text : null}
                                    {sch == 4 ? data.dosage_value_night + ' ' + data.dosage_form_text : null}
                                  </span>
                                </React.Fragment>}
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'instruction', schIndex)}>
                                  {(editable_grid == 'instruction_' + i.toString() + '_' + schIndex.toString()) ? this.getInstructionDropdownSchduled(data, schIndex + 1) : <React.Fragment>
                                    <span className={isEditGrid == undefined ? null : `${data.medicineType=='F'? 'custom-dp' : null}`}>
                                      {sch == 1 ? this.state.constants.drugMorningInstructions.map((data1, i) => data1.id == data.instruction_morning ? data1.description : null) : null}
                                      {sch == 2 ? this.state.constants.drugAfternoonInstructions.map((data1, i) => data1.id == data.instruction_afternoon ? data1.description : null) : null}
                                      {sch == 3 ? this.state.constants.drugEveningInstructions.map((data1, i) => data1.id == data.instruction_evening ? data1.description : null) : null}
                                      {sch == 4 ? this.state.constants.drugNightInstructions.map((data1, i) => data1.id == data.instruction_night ? data1.description : null) : null}
                                    </span>
                                  </React.Fragment>}
                                </td>

                              </tr>
                              {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                                <tr>
                                  <td colspan="9" className="remarks-column">
                                    <span className='bold-500'>Remarks :&nbsp;</span><span>{medicine_remarks}</span>
                                  </td>
                                </tr>
                                : null}
                              {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                                <>
                                  <tr>
                                    <td colspan="9" className="py-2">
                                      <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                      <table className="table" style={{ width: "auto" }}>
                                        <thead>
                                          <tr>
                                            <th width="200">Blood Sugar (mg/dL)</th>
                                            {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null}
                                            {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Insulin (Units)</td>
                                            {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null}
                                            {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                                : null}
                            </>
                          )
                          mederowCount = mederowCount + 1;
                        }
                        counter++;
                      })
                    }
                    else {
                      data.drug_duration_time = (data.daily_frequency == 8 || data.daily_frequency == 10) ? 'Months' : (data.daily_frequency == 9 || data.daily_frequency == 11) ? 'Weeks' : 'Days';
                      htmlData.push(
                        <>
                          <tr key={i} onClick={e=>this.saveIndex(e,i)} style={{ backgroundColor: this.state.viewType == 'copy-to-visit' && this.state.isEventBasedSS && LS_SERVICE.get('gender') == "Female" && LS_SERVICE.get('isObsGynaePatient') == true && custom_bgcolor ? custom_bgcolor : null }}>
                            {drugRowSpan > 1 && !medexists ? <td rowSpan={countRowspan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included' : 'text-dark')} style={{fontWeight:500}}>{count_med}</td> : null}
                            {drugRowSpan === 1 && !medexists ? <td rowSpan={countRowspan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included' : 'text-dark')} style={{fontWeight:500}}>{count_med}</td> : null}
                            {drugRowSpan > 1 && !medexists ? <td rowSpan={drugRowSpan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included remark-border' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included remark-border ' : 'text-dark tablet-border')} style={{ fontWeight: 500 }}>{data.drug_name}</td> : null}
                            {drugRowSpan === 1 && !medexists ? <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? (medicine_remarks !== '' && medicine_remarks !== null ? 'text-danger text-dark remarks-included remark-border' : 'text-danger text-dark') : (medicine_remarks !== '' && medicine_remarks !== null ? 'text-dark remarks-included  remark-border ' : 'text-dark tablet-border')} style={{ fontWeight: 500 }}>{data.drug_name}</td> : null}
                            {/* {this.state.viewType != 'copy-from-prescription' ? (
                              <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.strength?data.strength:'-'}</td>
                            ) : null} */}

                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'route')}>
                              {(editable_grid == 'route_' + i.toString()) ? this.getRouteDropdown(data) : <React.Fragment>
                                <span className={this.props.editGrid == undefined ? null : `${data.medicineType=='F'? 'custom-dp' : null} ${showBackgroundRoute == null &&  (data.medicineType=='F') ? 'custom-empty-pink' : null}`}>
                                  {data.route != null ? this.state.constants.route.map((data1, i) => data1.id == data.route ? data1.description : null) : '-'}
                                </span>
                              </React.Fragment>}
                            </td>

                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'frequency')}>
                              {(editable_grid == 'frequency_' + i.toString()) ? this.getDailyFrequencyDropdown(data, frequecyDefaultValue) : <React.Fragment> <span className={isEditGrid == undefined || classNameAdded ? null : `${data.medicineType=='F'? 'custom-dp' : null}`}>
                                {data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? `${this.state.drugDailyFrequency[data.daily_frequency]}  (${data.days_in_week_text})` : (data.drug_frequency == 1 ? this.state.drugDailyFrequency[data.daily_frequency] ? this.state.drugDailyFrequency[data.daily_frequency] : '-' : drugFreqText == "PRN" ? "PRN/SOS": drugFreqText)}
                              </span>
                              </React.Fragment>}
                            </td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'schedule')}> {(editable_grid == 'schedule_' + i.toString()) ? this.getDailyschedulesDropdown(data, schduledDefaultValue) : <React.Fragment> <span className={isEditGrid == undefined || classNameAdded ? null : `${data.medicineType=='F'? 'custom-dp' : null}`}>
                              {data.schedule_name != null && data.schedule_name ? data.schedule_name : '-'}
                            </span>
                            </React.Fragment>}
                            </td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'dosage')}>{(editable_grid == 'dosage_' + i.toString()) ? this.getDosageLoadDropdown(data,) : <React.Fragment> <span className={isEditGrid == undefined ? null : `${data.medicineType=='F'? 'custom-tb' : null} ${dosageBackground == null && (data.medicineType =='F') ? 'custom-empty-pink' : null}`}>

                              {
                                
                                  (data.dosage_value != undefined) ? data.dosage_value + ' ' + (data.dosage_form_text) ?? data.dosage_form_text : '-'}</span></React.Fragment>}</td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'instruction')}>{(editable_grid == 'instruction_' + i.toString()) ? this.getInstructionDropdown(data) : <React.Fragment>
                              <span className={isEditGrid == undefined ? null : `${data.medicineType=='F'? 'custom-dp' : null}`}>
                                {this.state.constants.drugInstructions.map((data1, i) => data1.id == data.instruction ? data1.description : null)}
                              </span>
                            </React.Fragment>}
                            </td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null} onClick={e => this.onClickMedData(data, i, e, 'duration')}>{(editable_grid == 'duration_' + i.toString()) ? this.getDurationDropdown(data, durationDefaultValue) : <React.Fragment>
                              <span className={isEditGrid == undefined || (classNameAdded && data.drug_frequency == 3) ? null : `${data.medicineType=='F'? 'custom-tb' : null} ${data.number_of_days ? '' : (parseInt(data?.drug_frequency) != 3 ? (parseInt(data?.daily_frequency) == 4 ? '' : '') : 'custom-empty-pink')}`}>
                                {data.number_of_days ? data.number_of_days + " " + (data?.number_of_days_type !== null ? data?.number_of_days_type : this.state.drugDurationFrequency[data.daily_frequency]) : (parseInt(data?.drug_frequency) != 3 ? (parseInt(data?.daily_frequency) == 4 ? '-' : 'Till review') : '-')}
                              </span>
                            </React.Fragment>}</td>

                            {(this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.state.eventBased == true ?
                              <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.event !== null ? this.state.constants.drugEvent.map((data1, i) => data1.id === data.event ? data1.description : null) : ''} {data.event == 1 || data.event == 2 ? data.lmp_event_duration : null} {data.event == 1 || data.event == 2 ? data.lmp_event_duration_type : null}</td>
                              : null}
                            {
                              this.state.viewType != 'template' && this.state.viewType != 'list-visit'
                                ?
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  <div>
                                    {
                                      data?.event == 4
                                        ?
                                        <>
                                          {
                                            moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                              ?
                                              '-'
                                              :
                                              <>
                                                <div className="form-group hasdata">
                                                  <DatePicker
                                                    id="protocol_start_date"
                                                    name="protocol_start_date"
                                                    data-drug={data.drug_id}
                                                    minDate={moment().toDate()}
                                                    defaultPickerValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    defaultValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    value={moment(protocol_start_date).format('D MMM, YYYY')}
                                                    className="form-control datetimepicker"
                                                    onChange={(e) => { this.updateStartDate(e, data.drug_id, 'med-table') }}
                                                    popperPlacement='bottomLeft'
                                                  />
                                                  <label htmlFor="key_finding_date_time">Custom Date</label>
                                                </div>
                                              </>
                                          }
                                        </>
                                        :
                                        <>
                                          {
                                            moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                              ?
                                              '-'
                                              :
                                              <>
                                                {moment(data.start_date).format('D MMM, YYYY')}
                                                <span style={{ fontSize: "12px" }}>
                                                  {
                                                    show_original_due == true
                                                      ?
                                                      <>
                                                        <br />
                                                        (Due from {moment(data.start_date_protocol).format('D MMM, YYYY')})
                                                      </>
                                                      :
                                                      null
                                                  }
                                                </span>
                                              </>
                                          }
                                        </>
                                    }
                                  </div>
                                  <div className={data.urgency == 1 ? "text-secondary" : null}>
                                    {data.urgency !== null ? this.state.constants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null) : ''}
                                  </div>
                                </td>
                                :
                                null
                            }
                            {this.props.showAction ?
                              <td>
                                {
                                  this.state.viewType != 'template' && this.state.viewType != 'soap-full' ?
                                    <a className="nostyle-link mr-2" onClick={e => this.handleEditDelete(e, 'showMedicineModal', data, 'editMedicine', i)}><i className="icon-edit"></i></a>
                                    :
                                    <a className="nostyle-link mr-2" onClick={e => this.props.handleEdit(data, i)}><i className="icon-edit"></i></a>
                                }
                                
                                <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', '', 'deleteMedicine', deleteIndex)}><i className="icon_delete"></i></a>
                                {this.state.cimsallow ? (
                                  <a className="nostyle-link ml-1" onClick={(e) => this.handleViewInfo(e, drugId)}><i className='icon_info' style={{width:'17px',height:'17px'}}></i></a>
                                ) : null}
                                {this.props.showSelectCheck ?
                                  <span className="select-diagnosis select-check">
                                    <i data-checkSelect={i} data-type={'drugs'} onClick={(e) => this.props.updateSelection(i, 'drugsData')}
                                      className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                                  </span>
                                  : null}
                              </td>
                              : null}
                          </tr>
                          {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                            <tr>
                              <td colspan="9" className="remarks-column">
                                <span className='bold-500'>Remarks :&nbsp;</span><span>{medicine_remarks}</span>
                              </td>
                            </tr>
                            : null}
                          {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                            <>
                              <tr>
                                <td colspan="9" className="py-2">
                                  <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                  <table className="table" style={{ width: "auto" }}>
                                    <thead>
                                      <tr>
                                        <th width="200">Blood Sugar (mg/dL)</th>
                                        {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null}
                                        {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Insulin (Units)</td>
                                        {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null}
                                        {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null}
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                            : null}
                        </>
                      )
                      mederowCount = mederowCount + 1;
                    }
                    delete data.drug_duration_time;
                    return htmlData;
                  })}
                </tbody>
              </table>
            </div>
          </>
          : (null)
        }

        <Modal
          visible={this.state.deleteMedicineModal}
          onCancel={this.handleModalPopup}
          style={{ top: 40 }}
          title="Are you sure you want to delete all medicines?"
          closable={false}
          footer={[
            <div className="text-center">
              <button
                className="btn btn-outline-secondary px-5 cancelOrderSet"
                onClick={this.handleModalPopup}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary px-5 deleteOrderSet ml-1"
                disabled={this.state.spin_loading}
                id='deleteButton'
                onClick={this.handleDeletePopSubmit}
              >
                DELETE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        ></Modal>

        <Modal
          visible={this.state.editMedicineDurationModal}
          onCancel={this.handleModalPopupDuration}
          style={{ top: 40 }}
          title="Are you sure you want to edit the duration for all the added medicines?"
          closable={false}
          footer={null} // Removed the footer array and placed buttons directly in the modal content
        >
          <div className='row mb-3 add_medicines edit_duration' >
            <div className="col-12">
              <div className={`form-group input-group frequency-field-row hasdata `} >
                <input
                  type="text"
                  style={{ height: '42px' }}
                  className={((this.state.number_of_days === '' || this.state.number_of_days == null)) ? "form-control onlynumbers custom_border-med" : "form-control onlynumbers custom_border-med"}
                  maxLength="3"
                  data-name="number_of_days"
                  name="drug_number_of_days"
                  id="number_of_days"
                  onChange={this.onChangeDays}
                  value={this.state.number_of_days}
                  placeholder="Duration"
                />
                <Select id="drug_duration_time" name="drug_duration_time"
                  onChange={this.onChangeDurationType} className="input-group-addons med_addon_height"
                  value={this.state.drug_duration_time}
                  disabled={this.props.formType !== 'template' || this.state.durationChange ? false : true}                         >
                  {this.optionDurationTypeLoad()}
                </Select>
              </div>

            </div>
          </div>
            <div className='row mb-3'>
              <div className="col-12 text-center">
                <p style={{color:'black'}}>
                  Please Note: Duration change will NOT be applied for Taper Dose, or medications with frequency not in sync with duration UOM.
                </p>
              </div>
            </div>
          <div className='row mb-3'>
            <div className="col-12 text-center">
              <button
                className="btn btn-outline-secondary px-3 mr-1 cancelOrderSet"
                onClick={this.handleModalPopupDuration}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary px-3 deleteOrderSet ml-1"
                id="deleteButton"
                onClick={this.saveAllMedicineData}
                disabled={!this.state.number_of_days} // Disable button if number_of_days is empty or null

              >
                SAVE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>
          </div>
        </Modal>

        <Modal className="myDrugInfo_modal" id="myDrugInfo"
          title="Drug Information"
          visible={this.state.isModalInfoVisible}
          onOk={() => this.handleInfoOk()}
          onCancel={() => this.handleInfoCancel()}
          footer={false}
          >
          <iframe id="drug_interaction_widget_iframe1" title="information" className="cimsiframe" src={`${CIMS_INFO}/${this.state.drug_id}/${this.state.store_id}/${this.state.facility_id}/${this.state.enterprise_id}`}></iframe>
        </Modal>

      </Fragment>
    );
  }
}

export default MedicineTable;
